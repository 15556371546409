import { displayNumber, formatPriceAndCurrency } from "../../../../shared/util/number";
import moment from "moment";

export const templateMlsCom = state => {
    try {
        // Description
        let description = state.WebRemarks.replace(/<\/?[^>]+(>|$)/g, '');

        // Image
        let mlsImages = []
        mlsImages.push(state.MasterImageWeb.replace('http', 'https'));

        // Specifications
        let specList = [];
        specList.push({ specName: "Type", specValue: state.PropertyType });
        specList.push({ specName: "Year Built", specValue: state.YearBuilt });
        specList.push({ specName: "Total Building Size", specValue: displayNumber(state.BuildingTotalSquareFeet) + ' SF' });
        specList.push({ specName: "Zoning", specValue: state.ZoningInformation });
        specList.push({ specName: "Property ID", specValue: state.PropertyId });

        return {
            // Listing Main Info
            title: `${state.PropertyName} - ${state.PropertyType} Space ${state.ListingType} in ${state.City}, ${state.StateCode}`,
            itemId: state.itemId,
            ListingID: state.ListingID,
            socialRealestateId: state.socialRealestateId,
            slug: state.slug,
            websiteSection: 'realestate',
            category: 'commercial',
            subcategory: state.subcategory || 'all',
            isMlsCom: state.isMlsCom,
            mls_prop_name: state.mls_prop_name,

            // Listing Properties
            propertyName: state.PropertyName,
            address: `${state.StreetNumber1} - ${state.StreetNumber2} ${state.StreetPrefix} ${state.StreetName} ${state.StreetSuffix}, Ste. ${state.SuiteName} , \n ${state.City} ${state.StateCode}, ${state.ZipCode}`,
            photos: mlsImages,
            listingType: state.ListingType,
            propertyType: state.PropertyType,
            specList: specList,
            floorSize: state.SquareFeetAvailable,
            description: description.replace(/-/g, ' '),
            floors: state.FloorCount,
            parking: state.TotalParking || '0',
            availableStatus: state.AvailabilityStatus,

            // Pricing
            price: state.ListPrice ? state.ListPrice : state.AskingRent,
            currency: 'USD',
            rentOrSell: state.rentOrSell,

            // Author Properties
            authorId: state.authorId,
            authorSlug: state.authorSlug,
            authorName: state.PrimaryOfficeName,
            userIsAuthorized: state.userIsAuthorized,
            authorProfileImage: state.authorProfileImage,

            // Contact Info
            officeName: state.PrimaryOfficeName,
            officeAddress: `${state.PrimaryOfficeAddress}, \n ${state.PrimaryOfficeCity} ${state.PrimaryOfficeState}, ${state.PrimaryOfficeZip}`,
            officeId: state.PrimaryOfficeID,
            PrimaryOfficeID: state.PrimaryOfficeID,
            numberToCall: state.PrimaryAgentPhone,
            sellerPhone: state.PrimaryAgentPhone,
            sellerEmail: state.PrimaryAgentEmail,
            contact: state.contact,
            seller1name: `${state.PrimaryAgentFirstName} ${state.PrimaryAgentLastName}`,
            seller1phone: state.PrimaryAgentPhone,
            seller1email: state.PrimaryAgentEmail,
            seller1ID: state.PrimaryAgentID,
            seller2name: `${state.Agent2FirstName} ${state.Agent2LastName}`,
            seller2phone: state.Agent2Phone,
            seller2email: state.Agent2Email,
            seller2ID: state.Agent2ID,
            seller3name: `${state.Agent3FirstName} ${state.Agent3LastName}`,
            seller3phone: state.Agent3Phone,
            seller3email: state.Agent3Email,
            seller3ID: state.Agent3ID,

            // Social
            itemIsShared: state.itemIsShared,
            shareCount: state.shareCount,
            viewCount: state.viewCount,
            likeCount: state.likeCount,
            itemIsLiked: state.itemIsLiked,
            likes: state.likes,
        };
    } catch (err) { console.log(err) }
}

export const templateMlsBiz = state => {
    try {
        // Description
        let description = state.WebRemarks.replace(/<\/?[^>]+(>|$)/g, '');
        let agentDescription = state.ListingAgentRemarks.replace(/<\/?[^>]+(>|$)/g, '');
        let livingQuartersDescription = state.LivingQuartersDescription.replace(/<\/?[^>]+(>|$)/g, '');

        // Business Address
        let businessAddress
        if (state.StreetName === 'Undisclosed') {
            businessAddress = `${state.City} ${state.StateCode}, ${state.ZipCode}`
        } else {
            businessAddress = `${state.StreetNumber1} ${state.StreetNumber2 ? '-' : ''} ${state.StreetNumber2} ${state.StreetPrefix} ${state.StreetName} ${state.StreetSuffix}, \n ${state.City} ${state.StateCode}, ${state.ZipCode}`
        }

        // Image
        let mlsImages = []
        mlsImages.push(state.MasterImageWeb.replace('http', 'https'));

        // Specifications
        let financialInfoSpecList = [];
        let businessOppSpecList = [];

        businessOppSpecList.push({ specName: 'Current Owner Years', specValue: state.CurrentOwnerYears });
        businessOppSpecList.push({ specName: 'Ownership Type', specValue: state.OwnershipType });
        state.OperatingInformation !== "Unknown" && businessOppSpecList.push({ specName: 'Operating Information', specValue: state.OperatingInformation });
        businessOppSpecList.push({ specName: 'Lease Expiration Date', specValue: state.LeaseExpires.split(' ')[0] });
        businessOppSpecList.push({ specName: 'Renewal Option', specValue: state.RenewalOption });
        businessOppSpecList.push({ specName: 'Rent Increase', specValue: state.RentIncrease });
        businessOppSpecList.push({ specName: '# of Full Time Employees', specValue: state.FullTimeEmployeeCount });
        businessOppSpecList.push({ specName: '# of Part Time Employees', specValue: state.PartTimeEmployeeCount });
        businessOppSpecList.push({ specName: '# of Owners', specValue: state.OwnerCount });
        businessOppSpecList.push({ specName: 'Owner Hours', specValue: state.OwnerHours });
        businessOppSpecList.push({ specName: 'Operating Hours', specValue: state.OperatingHours });
        businessOppSpecList.push({ specName: 'Year Established', specValue: state.YearEstablished });
        businessOppSpecList.push({ specName: 'Trade Name', specValue: state.TradeName });
        businessOppSpecList.push({ specName: 'Listing ID', specValue: state.ListingID });
        businessOppSpecList.push({ specName: 'Off Market Date', specValue: state.OffMarketDate });
        businessOppSpecList.push({ specName: 'Category', specValue: state.BusinessOpportunityType });
        businessOppSpecList.push({ specName: 'Assignable', specValue: state.Assignable });
        businessOppSpecList.push({ specName: 'Real Property', specValue: state.RealPropertyYN });
        businessOppSpecList.push({ specName: 'Other CBA ID', specValue: state.OtherCBAID });
        businessOppSpecList.push({ specName: 'Inventory Included', specValue: state.InventoryIncludedYN });
        businessOppSpecList.push({ specName: 'Inventory Value', specValue: state.InventoryValue });
        businessOppSpecList.push({ specName: 'Fixtures Equipment', specValue: state.FixturesEquipmentYN });
        businessOppSpecList.push({ specName: 'Fixtures Equipment Detail', specValue: state.FixturesEquipmentDetail });
        businessOppSpecList.push({ specName: 'Excluded Items', specValue: state.ExcludedItemsYN });

        state.MinimumDown && financialInfoSpecList.push({ specName: 'Minimum Down', specValue: formatPriceAndCurrency(state.MinimumDown, 'USD', ',') });
        financialInfoSpecList.push({ specName: 'Note Term', specValue: state.NoteTerm });
        financialInfoSpecList.push({ specName: 'Percent Interest', specValue: state.PercentInterest });
        state.OperatingIncome && financialInfoSpecList.push({ specName: 'Yearly Operating Income', specValue: formatPriceAndCurrency(state.OperatingIncome, 'USD', ',') });
        state.OperatingExpense && financialInfoSpecList.push({ specName: 'Yearly Operating Expense', specValue: formatPriceAndCurrency(state.OperatingExpense, 'USD', ',') });
        state.NetProfit && financialInfoSpecList.push({ specName: 'Net Profit', specValue: formatPriceAndCurrency(state.NetProfit, 'USD', ',') });
        financialInfoSpecList.push({ specName: 'Expense Includes Owner Salary', specValue: state.ExpenseIncludesOwnerSalaryYN });
        state.OwnerSalary && financialInfoSpecList.push({ specName: 'Owner Salary', specValue: formatPriceAndCurrency(state.OwnerSalary, 'USD', ',') });
        state.MonthlyRent && financialInfoSpecList.push({ specName: 'Monthly Rent', specValue: formatPriceAndCurrency(state.MonthlyRent, 'USD', ',') });
        state.MonthlyNNN && financialInfoSpecList.push({ specName: 'Monthly NNN/CAM', specValue: formatPriceAndCurrency(state.MonthlyNNN, 'USD', ',') });
        state.MonthlyEquipmentRental && financialInfoSpecList.push({ specName: 'Monthly Equipment Rental', specValue: formatPriceAndCurrency(state.MonthlyEquipmentRental, 'USD', ',') });
        financialInfoSpecList.push({ specName: 'All Cash', specValue: state.AllCash });
        financialInfoSpecList.push({ specName: 'Documents Supplied with Offer', specValue: state.DocsWithOffer });
        financialInfoSpecList.push({ specName: 'Profit/Loss Years Avaliable', specValue: state.ProfitLossYears });
        financialInfoSpecList.push({ specName: 'Balance Sheet Years Avaliable', specValue: state.BalanceSheetYearsAvailable });
        financialInfoSpecList.push({ specName: 'Schedule C Years', specValue: state.ScheduleCYears });
        state.YTDStatementYN !== "Unknown" && financialInfoSpecList.push({ specName: 'YTD Statement Available', specValue: state.YTDStatementYN });

        return {
            // Listing Main Info
            title: state.BusinessName,
            itemId: state.itemId,
            ListingID: state.ListingID,
            socialRealestateId: state.socialRealestateId,
            slug: state.slug,
            websiteSection: 'realestate',
            category: 'business-opportunities',
            subcategory: state.subcategory || 'all',
            isMlsBiz: state.isMlsBiz,
            mls_prop_name: state.mls_prop_name,

            // Listing Properties
            propertyName: state.BuildingName,
            businessName: state.BusinessName,
            address: businessAddress,
            streetName: state.StreetName,
            photos: mlsImages,
            webisteURL: state.WebsiteURL,
            description: description.replace(/-/g, ' '),
            agentDescription: agentDescription.replace(/-/g, ' '),
            livingQuartersDescription: livingQuartersDescription.replace(/-/g, ' '),
            listedDate: state.ListedDate,
            floorSize: state.LeaseableSquareFeet,
            parking: state.ParkingAvailable || '0',
            financialInfoSpecList: financialInfoSpecList || [],
            businessOppSpecList: businessOppSpecList || [],
            seating: state.Seating || 'N/A',
            showingInstruction: state.ShowingInstruction,
            availableStatus: state.AvailabilityStatus,
            livingQuarters: state.IncludesLivingQuartersYN,
            phoneSystem: state.PhoneSystemIncludedYN,
            beerWineLic: state.BeerWine,
            classHLic: state.ClassH,
            proLic: state.Professional,
            gamblingLic: state.Gambling,
            noSpecLic: state.NoSpecialLicense,
            daysOpen: state.DaysOpen,
            website: state.WebsiteURL,

            // Pricing
            price: state.ListPrice,
            currency: 'USD',

            // Author Properties
            authorId: state.authorId,
            authorSlug: state.authorSlug,
            authorName: state.PrimaryOfficeName,
            userIsAuthorized: state.userIsAuthorized,
            authorProfileImage: state.authorProfileImage,

            // Contact Info
            officeName: state.PrimaryOfficeName,
            officeAddress: `${state.PrimaryOfficeAddress}, \n ${state.PrimaryOfficeCity} ${state.PrimaryOfficeState}, ${state.PrimaryOfficeZip}`,
            officeId: state.PrimaryOfficeID,
            PrimaryOfficeID: state.PrimaryOfficeID,
            numberToCall: state.PrimaryAgentPhone,
            sellerPhone: state.PrimaryAgentPhone,
            sellerEmail: state.PrimaryAgentEmail,
            contact: state.contact,
            seller1name: `${state.PrimaryAgentFirstName} ${state.PrimaryAgentLastName}`,
            seller1phone: state.PrimaryAgentPhone,
            seller1email: state.PrimaryAgentEmail,
            seller1ID: state.PrimaryAgentID,
            seller2name: `${state.Agent2FirstName} ${state.Agent2LastName}`,
            seller2phone: state.Agent2Phone,
            seller2email: state.Agent2Email,
            seller2ID: state.Agent2ID,
            seller3name: `${state.Agent3FirstName} ${state.Agent3LastName}`,
            seller3phone: state.Agent3Phone,
            seller3email: state.Agent3Email,
            seller3ID: state.Agent3ID,

            // Social
            itemIsShared: state.itemIsShared,
            shareCount: state.shareCount,
            viewCount: state.viewCount,
            likeCount: state.likeCount,
            itemIsLiked: state.itemIsLiked,
            likes: state.likes,
        }
    } catch (err) { console.log(err) }
}

export const templateMlsNw = state => {
    try {

        console.log(state);

        // Description
        let description = state.PublicRemarks || '';

        // Address
        let address = `
            ${state.StreetNumberNumeric ? state.StreetNumberNumeric : ''} 
            ${state.StreetDirPrefix ? state.StreetDirPrefix : ''} 
            ${state.StreetName ? state.StreetName : ''} 
            ${state.StreetSuffix ? state.StreetSuffix : ''} 
            ${state.StreetDirSuffix ? state.StreetDirSuffix : ''} 
            \n 
            ${state.City ? state.City : ''} 
            ${state.StateOrProvince ? state.StateOrProvince : ''}, 
            ${state.PostalCode ? state.PostalCode : ''}, 
            ${state.Country ? state.Country : ''}
        `

        // Image
        let mlsImages = state.photos || [];

        // PropertyTypes.PropertyResiMedia.value.map(i => {
        //   mlsImages.push(i.MediaURL);
        // })

        // Spec List
        let specList = [];
        let soldInfo = [];

        specList.push({ specName: 'Listing ID', specValue: state.ListingId });
        specList.push({ specName: 'Parcel #', specValue: state.ParcelNumber });
        specList.push({ specName: 'Subdivision', specValue: state.SubdivisionName });
        state.LeasableArea && specList.push({ specName: 'Leasable Area', specValue: `${state.LeasableArea} SF` });
        state.PossibleUse[0] && specList.push({ specName: 'Possible Use', specValue: state.PossibleUse[0].replace(/,/g, '\n') });
        specList.push({ specName: 'No. of Units', specValue: state.NumberOfUnitsTotal });
        specList.push({ specName: 'Year Built', specValue: state.YearBuilt });
        state.NewConstructionYN && specList.push({ specName: 'New Construction', specValue: 'Yes' });
        specList.push({ specName: 'Property Condition', specValue: state.PropertyCondition });
        specList.push({ specName: 'Property Type', specValue: state.PropertyType });
        specList.push({ specName: 'Property Sub-Type', specValue: state.PropertySubType });
        specList.push({ specName: 'Structure Type', specValue: state.StructureType });
        specList.push({ specName: 'Style Code', specValue: state.NWM_StyleCode });
        state.LotSizeSquareFeet && specList.push({ specName: 'Lot Size (SF)', specValue: `${displayNumber(state.LotSizeSquareFeet || state.LotSizeAcres * 43560)} SF` });
        state.LotSizeAcres && specList.push({ specName: 'Lot Size (Acres)', specValue: `${state.LotSizeAcres} Acres` });
        state.LotFeatures[0] && specList.push({ specName: 'Lot Features', specValue: state.LotFeatures[0].replace(/,/g, '\n') });
        specList.push({ specName: 'Topography', specValue: state.Topography });
        state.TaxAnnualAmount && specList.push({ specName: 'Taxes Annual', specValue: formatPriceAndCurrency(state.TaxAnnualAmount, 'USD', ',') });
        specList.push({ specName: 'Tax Year', specValue: state.TaxYear });
        specList.push({ specName: 'Road Surface', specValue: state.RoadSurfaceType });
        state.FoundationDetails[0] && specList.push({ specName: 'Foundation Details', specValue: state.FoundationDetails[0].replace(/,/g, '\n') });
        state.Roof[0] && specList.push({ specName: 'Roof', specValue: state.Roof[0].replace(/,/g, '\n') });
        state.Sewer[0] && specList.push({ specName: 'Sewer', specValue: state.Sewer[0].replace(/,/g, '\n') });
        specList.push({ specName: 'Water Source', specValue: state.WaterSource });
        state.Vegetation[0] && specList.push({ specName: 'Vegetation', specValue: state.Vegetation[0].replace(/,/g, '\n') });
        specList.push({ specName: 'On Market Date', specValue: moment(state.OnMarketDate).format('L') });
        state.OffMarketDate && specList.push({ specName: 'Off Market Date', specValue: moment(state.OffMarketDate).format('L') });
        state.OriginalListPrice && specList.push({ specName: 'Original List Price', specValue: formatPriceAndCurrency(state.OriginalListPrice, 'USD', ',') });
        specList.push({ specName: 'Seller Disclosure', specValue: state.NWM_SellerDisclosure });

        soldInfo.push({ specName: 'Close Date', specValue: state.CloseDate });
        state.ClosePrice && soldInfo.push({ specName: 'Close Price', specValue: formatPriceAndCurrency(state.ClosePrice, 'USD', ',') });

        // Specifications
        return {

            // Dates
            listedDate: state.OnMarketDate,

            // Listing Main Info
            title: state.UnparsedAddress,
            isMlsNwResi: state.isMlsNwResi,
            isMlsNwRlse: state.isMlsNwRlse,
            isMlsNwRinc: state.isMlsNwRinc,
            isMlsNw: state.SourceSystemName === 'NWMLS',
            itemId: state.itemId,
            slug: state.slug,
            ListingId: state.ListingId,
            socialRealestateId: state.socialRealestateId,
            mls_prop_name: state.mls_prop_name,
            websiteSection: 'realestate',
            category: state.category[0],
            subcategory: state.subcategory[0] || 'all',
            soldInfo: soldInfo,
            cumulativeDaysOnMarket: state.CumulativeDaysOnMarket,
            source: state.SourceSystemName,
            availableStatus: state.MlsStatus,

            // Address
            address: address,
            propertyName: state.BuildingName,

            // Listing Properties
            specList: specList,
            streetName: state.StreetName,

            // Photos
            photos: mlsImages,

            // Description
            description: description.replace(/-/g, ' '),
            listingTerms: state.ListingTerms[0],
            offers: state.NWM_Offers,
            communityFeatures: state.CommunityFeatures[0],
            specialListingConditions: state.SpecialListingConditions,
            directions: state.Directions,
            showingInstruction: state.ShowingRequirements[0],
            entryLocation: state.EntryLocation,
            appliances: state.Appliances[0],
            inclusions: state.Inclusions,
            interiorFeatures: state.InteriorFeatures[0],
            flooring: state.Flooring[0],
            furnished: state.Furnished[0],
            exteriorFeatures: state.ExteriorFeatures[0],
            powerType: state.PowerProductionType[0],
            utilities: state.Utilities[0],
            fireplace: `${state.FireplaceYN ? "Yes" : "No"}`,
            fireplaceFeatures: state.FireplaceFeatures[0],
            fireplacesTotal: state.FireplacesTotal,
            basement: state.Basement,
            openParking: `${state.OpenParkingYN ? "Yes" : "No"}`,
            carport: `${state.CarportYN ? "Yes" : "No"}`,
            carportSpaces: state.carportSpaces,
            coveredSpaces: state.CoveredSpaces,
            associationFeeFrequency: state.AssociationFeeFrequency,
            associationFee: state.AssociationFee,
            electricOnPropertyYN: `${state.ElectricOnPropertyYN ? "Yes" : "No"}`,
            irrigationWaterRightsYN: `${state.IrrigationWaterRightsYN ? "Yes" : "No"}`,
            viewDescription: state.View[0],
            irrigationSource: state.IrrigationSource,
            buildingFeatures: state.BuildingFeatures[0],
            heatingType: state.NWM_HeatingCoolingType,
            buildingInformation: state.NWM_BuildingInformation,
            siteFeatures: state.NWM_SiteFeatures,
            signOnPropertyYN: `${state.SignOnPropertyYN ? "Yes" : "No"}`,


            // Icons
            parking: state.ParkingTotal || '0',
            openParkingSpaces: state.OpenParkingSpaces,
            bedrooms: state.BedroomsTotal,
            bathrooms: state.BathroomsFull,
            halfBathrooms: state.BathroomsHalf,
            floors: state.Levels || state.StoriesTotal,
            hasGarage: `${state.GarageYN ? "Yes" : "No"}`,
            hasView: `${state.ViewYN ? "Yes" : "No"}`,
            hasHeating: `${state.HeatingYN}`,
            waterFront: `${state.WaterfrontYN ? "Yes" : "No"}`,
            petsAllowed: state.PetsAllowed,

            // Specs
            floorSize: state.BuildingAreaTotal,
            floorSizeUnits: state.BuildingAreaUnits,

            // Nearby Schools
            elementarySchool: state.ElementarySchool,
            middleSchool: state.MiddleOrJuniorSchool,
            highSchool: state.HighSchool,
            highSchoolDistrict: state.HighSchoolDistrict,

            // Pricing
            price: state.ListPrice,
            currency: 'USD',

            // Author Properties
            authorId: state.authorId,
            authorSlug: state.authorSlug,
            authorName: state.ListOfficeName,
            userIsAuthorized: state.userIsAuthorized,
            authorProfileImage: state.authorProfileImage,

            // Contact Info
            officeName: state.ListOfficeName,
            ListOfficeMlsId: state.ListOfficeMlsId,
            contact: state.contact,
            seller1name: state.ListAgentFullName,
            seller1phone: state.ListOfficePhone,
            seller1phoneExt: state.ListOfficePhoneExt,

            // Social
            itemIsShared: state.itemIsShared,
            shareCount: state.shareCount,
            viewCount: state.viewCount,
            likeCount: state.likeCount,
            itemIsLiked: state.itemIsLiked,
            likes: state.likes,
        }
    } catch (err) { console.log(err) }
}

export const templateFollowone = state => {
    try {
        return {
            // Listing Main Info
            title: state.title,
            itemId: state.itemId,
            slug: state.slug,
            websiteSection: 'realestate-old',
            category: state.category,
            subcategory: state.subcategory || 'all',

            // Listing Properties
            address: state.theAddress,
            photos: state.allPhotos,
            bedCount: state.bedCount,
            bathCount: state.bathCount,
            specList: state.specList,
            description: state.description,
            floorSize: !state.specList ? '' : state.specList[2].specValue,
            floorSizeUnit: !state.specList ? '' : state.specList[2].specName,

            // Pricing
            price: state.price,
            currency: state.currency,
            rentOrSell: state.rentOrSell,
            afterDiscountPrice: state.afterDiscountPrice,
            callForPricing: state.callForPricing,
            addDiscount: state.addDiscount,
            discountId: state.discountId,

            // Author Properties
            authorId: state.authorId,
            authorSlug: state.authorSlug,
            authorName: state.authorName,
            userIsAuthorized: state.userIsAuthorized,
            authorProfileImage: state.authorProfileImage,

            seller1name: state.authorName,
            seller1phone: state.numberToCall,

            // Contact Info
            numberToCall: state.numberToCall,
            contact: state.contact,

            // Social
            itemIsShared: state.itemIsShared,
            shareCount: state.shareCount,
            viewCount: state.viewCount,
            likeCount: state.likeCount,
            itemIsLiked: state.itemIsLiked,
            likes: state.likes,
        }
    } catch (err) { console.log(err) }
}