const Sponsor = () => {
    return (
        <div id="our-sponsors" className="section sponsor">
            <h2>
                Transfer Agent 
            </h2>

            <div className="our-sponsors-content">
                <div className="our-sponsors-content-item">
                    <p>TRANSFER AGENT:</p>
                    <h3>vStock</h3>
                    <p>18 LAFAYETTE PL,WOODMERE, NY 11598<br />VSTOCKTRANSFER.COM</p>
                </div>
            </div>
        </div>
    );
};

export default Sponsor;