const CompanyStructure = () => {
    return (
        <div id="company-structure" className="section company-structure">
            <h2>Company Structure & Offices</h2>

            <div className="company-structure-content">

                <p>The Company currently has the following subsidiaries:</p>

                <ol>
                    <li>Followone Romania SRL (Romania EU);</li>
                    <li>Followone Group Inc. (Washington State);</li>
                    <li>Followone Real Estate Inc. (Washington State);</li>
                    <li>Healthcare Pro Group Inc. (Washington State) and</li>
                    <li>AdsB2b Inc. (Washington State).</li>
                </ol>

                <p>Followone Inc. wholly owns Followone Group Inc. and Followone Romania SRL. AdsB2b Inc., Followone Real Estate Inc., and Healthcare Pro Group Inc. are wholly owned by Followone Group Inc.</p>

                <p>Each subsidiary engages in the following business:</p>

                <ul>
                    <li><b>Followone Romania SRL</b> (incorporated in Romania EU provides Customer Service Support, Sales, Administration, Content Management and Development.</li>
                    <li><b>Followone Group Inc:</b> Holds the Trademarks and Intellectual Property.</li>
                    <li><b>Followone Real Estate Inc.:</b> is a Real Estate company that buys and sells properties. Offers advanced technology to Real Estate agents to manage listings and data.</li>
                    <li><b>Healthcare Pro Group Inc.:</b> a Holding company designed to hold and operate multiple healthcare businesses, using our proprietary technology.</li>
                    <li><b>AdsB2b Inc.:</b> a Marketing and Technology business that will offer software with management solutions for enterprise corporations.</li>
                </ul>

                <p>Followone, Inc. was incorporated on January 7, 2015 in the State of Washington. On May 29, 2018, the Company completed a domestication to the State of Delaware, and became a Delaware corporation on such date. The Company completed the domestication to become a Delaware corporation as the laws of the State of Delaware are more developed and Delaware is a more customary jurisdiction for a corporation that intends to be publicly held. In connection with the domestication to Delaware, all of the shares of capital stock of the Company as a Washington corporation became shares of capital stock of the Company as a Delaware corporation. All of the shareholders of the Company approved of the domestication of the Company from Washington to Delaware.</p>

                <p>Our headquarters are located at 777 108th AVE NE #2030, Bellevue, WA 98004, and our office phone number is 833-531-2151. Our website address is https://followone.com/.</p>

                <h3>Facilities</h3>

                <p>Our current lease agreement for our headquarters in Bellevue, WA, spans three years and is set to expire in July 2024. We retain the option to renew this lease for an additional 3 to 5 years at our discretion. The square footage of this space is 3,865. We believe that our existing facilities are adequate to support our existing operations and that we will be able to obtain appropriate additional facilities on commercially reasonable terms when necessary.</p>

                <p>Additionally, we maintain an office in Bucharest, Romania of approximately 1,700 square feet.</p>

                <div className="company-structure-locations">
                    <div className="company-structure-locations-item">
                        <b>Our US Mailing Address is:</b><br/>
                        8512 122nd Ave Ne #300<br/>
                        Kirkland, WA 98033
                    </div>

                    <div className="company-structure-locations-item">
                        <b>Our US HQ Address is:</b><br/>
                        777 108th AVE NE Suite #2030<br/>
                        BELLEVUE WA 98004
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyStructure;