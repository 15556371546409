const Valuation = () => {
    return (
        <div id="valuation" className="section valuation">
            <h2>Valuation</h2>

            <div className="valuation-content">
            <p>The valuation of Followone Inc. is underpinned by several key factors that highlight the company's technological foundation, business model, and growth potential. These elements collectively contribute to our current market valuation and future growth prospects:</p>

                <div className="valuation-content-item">
                    <h3>SaaS Technology Platform</h3>
                    <p>Our core technology is built on the modern MERN Stack, comprising MongoDB, Express JS, React JS, and Node JS, which enables a scalable, high-performance SaaS platform. This architecture allows us to deliver robust, cloud-based solutions that are flexible and easily adaptable to various business needs. Our mobile apps, developed using React Native, ensure a seamless and consistent user experience across devices, further enhancing the platform's accessibility and reach.</p>
                </div>

                <div className="valuation-content-item">
                    <h3>AI Implementation Across Verticals</h3>
                    <p>Artificial Intelligence (AI) will be deeply integrated across all our business verticals, driving efficiency, personalization, and data-driven decision-making. AI powers features such as predictive analytics, recommendation engines, automated customer service, and intelligent content management, will allow us to optimize user experience and operational processes. This integration will not only enhance the value proposition for our clients but also will positions Followone Inc. at the forefront of technological innovation.</p>
                </div>

                <div className="valuation-content-item">
                    <h3>Social Media Technology Platform</h3>
                    <p>Our platform includes a comprehensive suite of social media features, enabling users to create personal profiles, share videos, photos, blogs, and news. This social media layer enhances user engagement and provides a powerful tool for businesses to connect with their customers. The integration of social media technology into our platform drives user-generated content, increasing platform activity and stickiness.</p>
                </div>

                <div className="valuation-content-item">
                    <h3>Advertising Business Model and Technology</h3>
                    <p>We have developed a sophisticated advertising business model supported by cutting-edge technology that enables targeted advertising across our platform. This model leverages user data, and our social media features to deliver highly relevant ads, maximizing ROI for advertisers while generating significant revenue streams for Followone Inc. Our advertising platform is designed to scale with the growth of our user base and data assets, ensuring long-term sustainability.</p>
                </div>

                <div className="valuation-content-item">
                    <h3>Vertically Integrated Business Model</h3>
                    <p>Followone Inc. operates under a vertically integrated business model, bringing together multiple industries and services under one umbrella. This integration allows us to leverage synergies across different sectors, streamline operations, and offer a comprehensive suite of services to our clients.</p>
                </div>

                {/* <div className="valuation-content-item">
                    <h3>Estimated Revenue by End of 2025: $105M</h3>
                    <p>Our revenue projections indicates a pending M&A, with an estimated revenue of $105 million by end of 2025.</p>
                </div> */}

                {/* <div className="valuation-content-item">
                    <h3>Merger & Acquisition Business Model</h3>
                    <p>Followone Inc. employs an aggressive Merger & Acquisition (M&A) strategy as a core component of our growth plan. This approach allows us to rapidly expand our market presence, acquire complementary technologies and businesses, and integrate them into our vertically integrated model. Our M&A strategy is designed to drive growth, enhance our competitive advantage, and increase shareholder value.</p>
                </div> */}

                <div className="valuation-content-item">
                    <h3>Data Management & Technology</h3>
                    <p><b>Auto:</b> Our platform includes approximately 6 million vehicles for 108k auto dealers, on a daily basis, per platform.</p>

                    {/* We own 4 platforms for auto dealers: Followone Auto, ZakList,CarzLook and CarVerve. */}
                    {/* <p><b>Case Study: Cost Savings Through Proprietary Software</b> Example, just for 61,000 car dealers and 244,000 content employees – considering 4 content employees per dealer, each with a salary of $60,000 per year, equals a total cost of $14.6 billion. Our proprietary software for auto dealers, utilized across our platforms: Followone Auto, ZakList, Carzlook, and CarVerve, can save the industry $14.6 billion annually in workforce. This is achieved by reducing the need for 244,000 content employees, typically required to manage auto inventory across 61,000 dealerships. Each of our platforms updates approximately 6 million cars daily, managing content for 108,000 car dealers. In total our software manages 24,000,000 vehicles daily.</p> */}

                    <p><b>Real Estate:</b> Our platform includes comprehensive data on 500k properties, with 50k active listings from Washington State commercial and residential, and commercial from Idaho and Oregon. We also feature business opportunities.</p>

                    {/* <p><b>Store:</b> We manage 3 million products, supporting our e-commerce and retail verticals with extensive inventory data that drives sales and user engagement.</p> */}
                </div>

                <div className="valuation-content-item">
                    <h3>User-Generated Content Platform</h3>
                    <p>Our platform is designed to facilitate user-generated content (UGC) from both consumers and businesses, with a particular focus on enterprise-level interactions. This UGC model enhances our content offering, driving engagement and providing valuable insights into customer preferences and market trends. The emphasis on business and enterprise content further strengthens our position as a leading platform for B2B and B2C interactions.</p>
                    <p>The valuation of Followone Inc. is based on a strong technological foundation, innovative business models, and a clear growth trajectory supported by AI, data management, and a vertically integrated structure. With robust revenue projections and a strategic focus on mergers and acquisitions, Followone Inc. is well-positioned for sustained growth and long-term success.</p>
                </div>
            </div>
        </div>
    );
};

export default Valuation;