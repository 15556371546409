import realestateLogo from "../../../../assets/about/verticals/realestate.svg";
import autosLogo from "../../../../assets/about/verticals/auto.svg";
import storeLogo from "../../../../assets/about/verticals/store.svg";
import jobsLogo from "../../../../assets/about/verticals/jobs.svg";
import servicesLogo from "../../../../assets/about/verticals/services.svg";
import travelLogo from "../../../../assets/about/verticals/travel.svg";
import healthcareLogo from "../../../../assets/about/verticals/healthcare.svg";
import followoneAiLogo from "../../../../assets/about/followone-ai.svg";

const EndCover = () => {
    return (
        <div id="end-cover" className="section end-cover">
            <h4>Business Verticals</h4>

            <table>
                <tbody>
                    <tr>
                        <td><img src={realestateLogo} alt="followone.com/realestate" /></td>
                        <td>followone.com/realestate</td>
                    </tr>

                    <tr>
                        <td><img src={autosLogo} alt="followone.com/autos" /></td>
                        <td>followone.com/autos</td>
                    </tr>

                    <tr>
                        <td><img src={storeLogo} alt="followone.com/store" /></td>
                        <td>followone.com/store</td>
                    </tr>

                    <tr>
                        <td><img src={jobsLogo} alt="followone.com/jobs" /></td>
                        <td>followone.com/jobs</td>
                    </tr>

                    <tr>
                        <td><img src={servicesLogo} alt="followone.com/services" /></td>
                        <td>followone.com/services</td>
                    </tr>

                    <tr>
                        <td><img src={travelLogo} alt="followone.com/travel" /></td>
                        <td>followone.com/travel</td>
                    </tr>

                    <tr>
                        <td><img src={healthcareLogo} alt="followone.com/healthcare" /></td>
                        <td>followone.com/healthcare</td>
                    </tr>
                </tbody>
            </table>

            <div className="followone-ai">
                <img src={followoneAiLogo} alt="Followone AI" />
            </div>
        </div>
    );
};

export default EndCover;