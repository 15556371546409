import CorePillars1 from '../../../../assets/about/pillar-o.jpeg';
import CorePillars2 from '../../../../assets/about/pillar-n.jpeg';
import CorePillars3 from '../../../../assets/about/pillar-e.jpeg';

const CorePillars = () => {
    return (
        <div id="core-pillars" className="section pillars">
            <h2>Core Pillars</h2>

            <div className="core-pillars-content">
                <div className="core-pillars-content-images">
                    <img src={CorePillars1} alt="Core Pillars 1" />
                    <img src={CorePillars2} alt="Core Pillars 2" />
                    <img src={CorePillars3} alt="Core Pillars 3" />
                </div>

                <div className="core-pillars-content-text">
                    <p>Followone is built on three core pillars: One Meaning, focusing on connectivity and unity across our platform, One Mission, dedicated to transforming the digital marketplace, and One Philosophy, emphasizing simplicity and fair relationships in our tech-driven world. These pillars serve as our roadmap for building an integrated, smooth future.</p>

                    <div>
                        <h3>One Meaning</h3>
                        <p>Follow(one) embodies two key concepts: connectivity and unity. "Follow" captures the essence of social interactions—leading, being led, influencing, and engaging—essential for building networks and expanding influence. "One" represents unity in diversity, highlighting our commitment to inclusivity and bringing together people, services, and ideas under one platform. Together, Followone fosters a community where connections and unity are celebrated.</p>
                    </div>

                    <div>
                        <h3>One Mission</h3>
                        <p>Followone simplifies life by offering an all-in-one platform. Whether it's buying or selling products, finding a home, ordering food, or managing healthcare, everything is accessible with one login and interface. Our mission is to bridge the gap between businesses and consumers, creating a seamless ecosystem for everyday needs.</p>
                    </div>

                    <div>
                        <h3>One Philosophy</h3>
                        <p>Followone is dedicated to enhancing society by promoting fair and transparent interactions between companies and customers. Our goal is to remove obstacles, ensure equity in transactions, and build a cohesive community. Ultimately, Followone aims to be more than just a platform — it's a catalyst for a simpler, more harmonious way of life.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CorePillars;