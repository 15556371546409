import ImgCover from '../../../../assets/about/cover-symetra.jpg'
import AILogo from '../../../../assets/about/followone-ai-w.svg'

const Cover = () => {
    return (
        <div id="cover" className="section cover" style={{ backgroundImage: `url(${ImgCover})` }}>

            <div className="cover-background"></div>

            <div className="cover-logo">
                <img src={AILogo} alt="Followone AI Logo" />
            </div>

            {/* <div className="cover-text">
                <h1>Followone Inc.<br />a Delaware Corporation</h1>
                <p>Located in the Symetra Building,<br /> in downtown Bellevue, WA</p>
            </div> */}

            <div className="cover-text">
                <h1>About Us</h1>
                <p>Let's get to know each other.</p>
            </div>

            <div className="cover-addresses">
                <div>
                    <h4>Followone HQ</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td><i className="fal fa-map-marker-alt"></i></td>
                                <td>
                                    777 108th AVE NE, #2030<br />
                                    Bellevue, WA 98004
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <h4>Office Contact</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td><i className="fal fa-phone-office"></i></td>
                                <td>+1 (833) 531-2151</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-envelope"></i></td>
                                <td>support@followone.com</td>
                            </tr>
                            <tr>
                                <td><i className="fal fa-globe"></i></td>
                                <td>https://followone.com</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Cover;