import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import actionType from '../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { formatPriceAndCurrency } from "../../../shared/util/number";
import { Link } from 'react-router-dom';
import EngageBox from '../../../shared/uielements/Engage/EngageBox';
// import RateButton from '../../../shared/uielements/Engage/EngageButtons/RateButton';
import EngageButtons from '../../../shared/uielements/Engage/EngageButtons/EngageButtons';
import FavoritesButton from '../../../shared/uielements/Engage/EngageButtons/FavoritesButton';
import LoveButton from '../../../shared/uielements/Engage/EngageButtons/LoveButton';
import ViewsButton from '../../../shared/uielements/Engage/EngageButtons/ViewsButton';
// import RealEstateFloorSize from "./Assets/realestate-surface.svg";
// import PaperImage from '../../../assets/rip-papers.png';
import moment from 'moment';
import ListingPreviewAdminPanel from '../../../shared/components/SideBar/AdminPanel/ListingPreviewAdminPanel';
import RealEstatePreviewInfo from "./Components-Preview/real-estate-pv-info";
import RealEstatePreviewMeta from "./Components-Preview/real-estate-pv-meta";


class ListingPreview extends Component {
    state = {
        afterDiscountPrice: '',
        oldAfterDiscountPrice: ''
    }

    componentDidMount() {
        let c = this.props.currency;
        let p = this.props.price;
        let d = this.props.discountId;
        if (!!d) {
            let theDiscount = Number(d.couponValue);
            if (d.couponCurrency) {
                if (d.couponCurrency === '%') {
                    p = Math.round((p - p * theDiscount / 100) * 100) / 100;
                } else {
                    theDiscount = theDiscount / 100;// Math.floor(theDiscount / 100) + (theDiscount % 100) / 100;
                    p = Math.round((p - theDiscount) * 100) / 100;
                }
            }
            if (p > 0) {
                this.setState({ afterDiscountPrice: formatPriceAndCurrency(p, c, ',') });
            }
        }
    }

    componentDidUpdate() {
        let c = this.props.currency;
        let p = this.props.price;
        let d = this.props.discountId;
        if (!!d) {
            let theDiscount = Number(d.couponValue);
            if (d.couponCurrency) {
                if (d.couponCurrency === '%') {
                    p = Math.round((p - p * theDiscount / 100) * 100) / 100;
                } else {
                    theDiscount = theDiscount / 100;
                    p = Math.round((p - theDiscount) * 100) / 100;
                }
            }
            if (p > 0 && (this.state.oldAfterDiscountPrice !== this.state.afterDiscountPrice || !this.state.afterDiscountPrice)) {
                this.setState({
                    oldAfterDiscountPrice: this.state.afterDiscountPrice,
                    afterDiscountPrice: formatPriceAndCurrency(p, c, ',')
                });
            }
        }
    }

    getDuration(b, e) {
        let d = moment(e).diff(moment(b), 'days') + 1;
        if (d === 1) return '1 day';
        else return d + ' days';
    }

    getPropertySpecs(specs) {
        let result = '';
        if (Number(specs.bedCount) > 0) {
            result = result + specs.bedCount + ' Bed';
            if (Number(specs.bedCount) > 1) result = result + 's';
        }
        if (Number(specs.bathCount) > 0) {
            if (result) result = result + ' | ';
            result = result + specs.bathCount + ' Bath';
            if (Number(specs.bathCount) > 1) result = result + 's';
        }
        if (specs.floorSize) {
            if (result) result = result + ' | ';
            result = result + specs.floorSize;
            if (Number(specs.floorSize) > 0) result = result + ' sqft';
        }
        return result;
    }

    onClickItem = (e) => {

        console.log('asddsdas', this.props);

        let section = this.props.originalWebsiteSection;
        if (section === 'realestate-old') section = 'realestate';

        let subcategory 

        if (this.props.isMlsNwResi) {
            subcategory = (this.props.subcategory[0] || 'other').replace(/\//g, '_');
        } else {
            subcategory = (this.props.subcategory || 'other').replace(/\//g, '_');
        }

        
        let urlSlug = `/${section || 'realestate'}/${this.props.category}/${subcategory}/${this.props.slug}`;
        if (this.props.onClick !== undefined) this.props.onClick();
        if (this.props.onToggleSidebar !== undefined) this.props.onToggleSidebar();
        // this.props.history.push(urlSlug || '/store');
        this.props.pageShouldRefresh(urlSlug);
    }

    render() {
        let thisCouponValue = (!this.props.discountId) ? 0 : (this.props.discountId.couponCurrency === '%') ? Number(this.props.discountId.couponValue) : Math.floor(Number(this.props.discountId.couponValue) / 100) + (Number(this.props.discountId.couponValue) % 100) / 100;
        // let saleButtonCta = (!!this.props.callForPricing || !this.props.addDiscount) ? (this.props.rentOrSell && this.props.rentOrSell === 'rent') ? (this.props.category === 'commercial') ? getTranslatedPhrase(this.props.userLanguage, 'Lease Now') :
        //     getTranslatedPhrase(this.props.userLanguage, 'Rent Now') : getTranslatedPhrase(this.props.userLanguage, 'Shop Now') : ((this.props.addDiscount === 'coupon') ?
        //         getTranslatedPhrase(this.props.userLanguage, 'Get') + ' ' +
        //         formatPriceAndCurrency(thisCouponValue, this.props.discountId.couponCurrency, ',') + ' ' +
        //         getTranslatedPhrase(this.props.userLanguage, 'Coupon') : getTranslatedPhrase(this.props.userLanguage, 'Get Deal'));

        let buttonClassname = 'button button-clear button-small';
        if (!this.props.callForPricing && !!this.props.addDiscount)
            buttonClassname = 'button button-sale button-small';

        let { onClickOnFavorite = () => null } = this.props;

        let section = this.props.originalWebsiteSection;
        if (section === 'realestate-old') section = 'realestate';
        let subcategory = ((this.props.subcategory || 'other') + '').replace(/\//g, '_');
        let linkSlug = `/${section || 'realestate'}/${this.props.category}/${subcategory}/${this.props.slug}`;
        let imageSrc = (this.props.featuredImage || '').indexOf('http') >= 0 ? this.props.featuredImage : this.props.featuredImage;


        let listing;

        if (this.props.isMlsCom) {
            // Image
            let mlsImages = []
            mlsImages.push(this.props.MasterImageWeb.replace('http', 'https'));

            listing = {
                // Listing Main Info
                title: `${this.props.PropertyName} - ${this.props.PropertyType} Space ${this.props.ListingType} in ${this.props.City}, ${this.props.StateCode}`,
                itemId: this.state.itemId,
                slug: this.state.slug,
                websiteSection: 'realestate',
                category: 'commercial',
                subcategory: this.state.subcategory,
                PropertyType: this.props.PropertyType,

                // Listing Properties
                city: this.props.City,
                state: this.props.StateCode,
                photos: mlsImages,
                listingType: this.props.ListingType,
                floorSize: this.props.SquareFeetAvailable,
                // description: description.replace(/-/g, ' '),

                // Pricing
                price: this.props.ListPrice ? this.props.ListPrice : this.props.AskingRent,
                currency: 'USD',
                rentOrSell: this.state.rentOrSell,

                // Author Properties
                authorId: this.state.authorId,
                authorSlug: this.state.authorSlug,
                authorName: this.props.PrimaryOfficeName,
                userIsAuthorized: this.state.userIsAuthorized,
                authorProfileImage: this.state.authorProfileImage,
                cumulativeDaysOnMarket: this.props.CumulativeDaysOnMarket,

                // Contact Info
                officeName: this.props.PrimaryOfficeName,

                // Social
                itemIsShared: this.state.itemIsShared,
                shareCount: this.state.shareCount,
                viewCount: this.state.viewCount,
                likeCount: this.state.likeCount,
                itemIsLiked: this.state.itemIsLiked,
                likes: this.state.likes,
            }
        } else if (this.props.isMlsBiz) {
            // Image
            let mlsImages = []
            mlsImages.push(this.props.MasterImageWeb.replace('http', 'https'));

            listing = {
                // Listing Main Info
                title: this.props.BusinessName,
                itemId: this.state.itemId,
                slug: this.state.slug,
                websiteSection: 'realestate',
                category: 'business-opportunities',
                subcategory: this.state.subcategory,
                PropertyType: this.props.PropertyType,
                cumulativeDaysOnMarket: this.props.CumulativeDaysOnMarket,

                // Listing Properties
                photos: mlsImages,
                city: this.props.City,
                state: this.props.StateCode,
                // description: description.replace(/-/g, ' '),
                floorSize: this.props.LeaseableSquareFeet,

                // Pricing
                price: this.props.ListPrice,
                currency: 'USD',

                // Author Properties
                authorId: this.state.authorId,
                authorSlug: this.state.authorSlug,
                authorName: this.props.PrimaryOfficeName,
                userIsAuthorized: this.state.userIsAuthorized,
                authorProfileImage: this.state.authorProfileImage,

                // Contact Info
                officeName: this.props.PrimaryOfficeName,

                // Social
                itemIsShared: this.state.itemIsShared,
                shareCount: this.state.shareCount,
                viewCount: this.state.viewCount,
                likeCount: this.state.likeCount,
                itemIsLiked: this.state.itemIsLiked,
                likes: this.state.likes,
            }


        } else if (this.props.SourceSystemName === "NWMLS") {
            listing = {
                // Listing Main Info
                title: this.props.title,
                itemId: this.props.itemId,
                slug: this.props.slug,
                websiteSection: this.props.originalWebsiteSection,
                category: this.props.category[0],
                subcategory: this.props.subcategory[0],
                PropertyType: this.props.PropertyType,

                // Listing Properties
                city: this.props.city,
                state: this.props.state,
                photos: imageSrc,
                // bedCount: this.props.bedCount,
                // bathCount: this.props.bathCount,
                // description: this.props.description,
                floorSize: this.props.BuildingAreaTotal,
                floorSizeUnits: this.props.BuildingAreaUnits,
                bedrooms: this.props.BedroomsTotal,
                bathrooms: this.props.BathroomsFull,
                cumulativeDaysOnMarket: this.props.CumulativeDaysOnMarket,

                // Pricing
                price: this.props.price,
                currency: this.props.currency,
                rentOrSell: this.props.rentOrSell,
                afterDiscountPrice: this.state.afterDiscountPrice,
                callForPricing: this.props.callForPricing,
                addDiscount: this.props.addDiscount,
                discountId: this.props.discountId,

                // Author Properties
                authorId: this.props.authorId,
                authorSlug: this.props.authorSlug,
                authorName: this.props.authorName,
                userIsAuthorized: this.props.userIsAuthorized,
                // authorProfileImage: this.props.authorProfileImage,

                // Social
                itemIsShared: this.props.itemIsShared,
                shareCount: this.props.shareCount,
                viewCount: this.props.viewCount,
                likeCount: this.props.likeCount,
                itemIsLiked: this.props.itemIsLiked,
                likes: this.props.likes,
            }
        } else {
            listing = {
                // Listing Main Info
                title: this.props.title,
                itemId: this.props.itemId,
                slug: this.props.slug,
                websiteSection: this.props.originalWebsiteSection,
                category: this.props.category,
                subcategory: this.props.subcategory,
                PropertyType: this.props.PropertyType,

                // Listing Properties
                city: this.props.city,
                state: this.props.state,
                photos: imageSrc,
                // bedCount: this.props.bedCount,
                // bathCount: this.props.bathCount,
                // description: this.props.description,
                floorSize: !this.props.specList ? '' : this.props.specList[2].specValue,
                floorSizeUnit: !this.props.specList ? '' : this.props.specList[2].specName,
                cumulativeDaysOnMarket: this.props.CumulativeDaysOnMarket,

                // Pricing
                price: this.props.price,
                currency: this.props.currency,
                rentOrSell: this.props.rentOrSell,
                afterDiscountPrice: this.state.afterDiscountPrice,
                callForPricing: this.props.callForPricing,
                addDiscount: this.props.addDiscount,
                discountId: this.props.discountId,

                // Author Properties
                authorId: this.props.authorId,
                authorSlug: this.props.authorSlug,
                authorName: this.props.authorName,
                userIsAuthorized: this.props.userIsAuthorized,
                // authorProfileImage: this.props.authorProfileImage,

                // Social
                itemIsShared: this.props.itemIsShared,
                shareCount: this.props.shareCount,
                viewCount: this.props.viewCount,
                likeCount: this.props.likeCount,
                itemIsLiked: this.props.itemIsLiked,
                likes: this.props.likes,
            }
        }

         console.log(listing);

        return (

            <div className="category-list_item store-item-preview">

                <Link to={linkSlug} className="store-listing-preview-img-bkg" onClick={this.onClickItem} style={{ backgroundImage: `url(${listing.photos})` }} />

                <div className="slp">

                    <RealEstatePreviewInfo
                        rentOrSell={listing.rentOrSell}
                        listingType={listing.listingType}
                        category={listing.category}
                        subcategory={listing.subcategory}
                        PropertyType={listing.PropertyType}
                        isMlsBiz={this.props.isMlsBiz || listing.category === 'business-opportunities'}
                        floorSize={listing.floorSize}
                        floorSizeUnit={listing.floorSizeUnit}
                        callForPricing={listing.callForPricing}
                        addDiscount={listing.addDiscount}
                        price={listing.price}
                        currency={listing.currency}
                        rateType={listing.rateType}
                        isMlsCom={this.props.isMlsCom}
                        afterDiscountPrice={listing.afterDiscountPrice}
                        cumulativeDaysOnMarket={listing.cumulativeDaysOnMarket}
                    />

                    <RealEstatePreviewMeta
                        title={listing.title}
                        authorName={listing.authorName}
                        officeName={listing.officeName}
                        city={listing.city}
                        state={listing.state}
                        bathrooms={listing.bathrooms}
                        bedrooms={listing.bedrooms}
                    />

                    <div className="slp-buttons">
                        <Link to={linkSlug} onClick={this.onClickItem}
                            className={buttonClassname}>{getTranslatedPhrase(this.props.userLanguage, 'See More Details')}</Link>
                        <ListingPreviewAdminPanel
                            itemType={this.props.originalWebsiteSection}
                            itemSlug={this.props.slug}
                            itemId={this.props.itemId}
                            userIsAuthorized={this.props.isAuthor || (this.props.loggedInUser && (this.props.loggedInUser.userLevel === 'admin' || this.props.loggedInUser.userLevel === 'customer-service'))}
                        />
                    </div>


                    <div className="slp-social">
                        <EngageBox
                            websiteSection={this.props.originalWebsiteSection}
                            itemId={this.props.itemId}
                            favoriteItemId={this.props.favoriteItemId}
                            authorId={this.props.authorId}
                            mlsProp={this.props.mls_prop_name || ''}
                            onClickOnFavorite={onClickOnFavorite}
                        />
                    </div>

                    {/* {!this.props.onlyFavoritesButton ? (
                        <div className="slp-social">
                            <EngageBox
                                websiteSection={this.props.originalWebsiteSection}
                                itemId={this.props.itemId}
                                favoriteItemId={this.props.favoriteItemId}
                                authorId={this.props.authorId}
                                mlsProp={this.props.mls_prop_name || ''}
                                onClickOnFavorite={onClickOnFavorite}
                            />
                        </div>
                    ) : (<div className="slp-social"><EngageButtons>
                        <LoveButton
                            isDisabled={true}
                            noLove={this.props.likeCount}
                            isClicked={this.props.itemIsLiked}
                            onClick={() => null}
                        />
                        <ViewsButton
                            isDisabled={true}
                            noViews={this.props.viewCount}
                            isClicked={false}
                        />
                        <FavoritesButton
                            isDisabled={false}
                            isClicked={true}
                            onClick={onClickOnFavorite}
                        />
                    </EngageButtons></div>)} */}
                </div>
            </div>);
    }
}


const mapStateToListingPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

const mapDispatchToListingPreviewProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(mapStateToListingPreviewProps, mapDispatchToListingPreviewProps)(withRouter(ListingPreview));