import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import SideBar from "./components/SideBar";

import Cover from "./components/Cover"; 
import Valuation from "./components/Valuation";
import WhoWeAre from "./components/WhoWeAre";
import CorePillars from "./components/CorePillars";
import Verticals from "./components/Verticals";
import Sponsor from "./components/Sponsor";
import CompanyStructure from "./components/CompanyStructure";
import Management from "./components/Management";
import CultureEmployees from "./components/CultureEmployees";
import MarketAnalysis from "./components/MarketAnalysis";
import BusinessModel from "./components/BusinessModel";
import Technology from "./components/Technology";
import FollowoneAI from "./components/FollowoneAI";
import FollowoneCom from "./components/FollowoneCom";
import EndCover from "./components/EndCover";

const Investors = props => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="main-100 about">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Investors | Followone</title>
                <meta name="description" content="Our vision is to help businesses achieve leading growth, while creating a platform that enables unique products to be sold by us, and third parties worldwide." />
                <link rel="canonical" href="https://followone.com/investors" />
            </Helmet>

            <div className="about-page-new">
                <div>
                    <SideBar />
                </div>
                

                <div className="content">
                    {/* Cover */}
                    <Cover />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>

                    {/* Valuation */}
                    <Valuation /> 
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a> 

                    {/* Our Sponsor, Transfer Agent & Legal Counsel */}
                    <Sponsor />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a> 

                    {/* Who We Are */}
                    <WhoWeAre />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>

                    {/* Core Pillars */}
                    <CorePillars />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>
                    
                    {/* Business Verticals */}
                    <Verticals /> 
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>
                    
                    {/* Company Structure */}
                    <CompanyStructure />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>

                    {/* Management */}
                    <Management />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>

                    {/* Culture & Employees */}
                    <CultureEmployees />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>
                    
                    {/* Market Analysis */}
                    <MarketAnalysis />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>
                    
                    {/* Business Model */}
                    <BusinessModel />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a> 

                    {/* Technology */}
                    <Technology />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>

                    {/* Followone AI */}
                    <FollowoneAI />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>

                    {/* Followone.com */}
                    <FollowoneCom />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>
                    
                    {/* End Cover */}
                    <EndCover />
                    <a className="back-to-top" href="/investors#top"><i className="fal fa-chevron-circle-up" /> To Top</a>
                </div>
            </div>
        </div>
    );
}

export default Investors;
