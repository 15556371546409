const Verticals = () => {
    return (
        <div id="business-verticals" className="section verticals">
            <h2>Verticals</h2>

            <div className="business-verticals-content">
                <div className="business-verticals-content-item">
                    <h3>Followone Auto</h3>
                    <p>Enhances car dealers' visibility with a dedicated landing page and expanded exposure across multiple platforms, allowing them to showcase inventory, promote services, and engage with customers.</p>
                    {/* <a href="/">Learn More</a> */}
                </div>

                <div className="business-verticals-content-item">
                    <h3>Followone Real Estate</h3>
                    <p>Simplifies property management for agents with a unified platform for commercial and residential listings, and empowers homeowners with free, direct property listings, bridging the gap in the real estate market.</p>
                    {/* <a href="/">Learn More</a> */}
                </div>

                <div className="business-verticals-content-item">
                    <h3>Followone Store</h3>
                    <p>Offers businesses an extensive online showroom and provides individual users a platform to sell products, all while preparing to launch a discounted product marketplace.</p>
                    {/* <a href="/">Learn More</a> */}
                </div>

                <div className="business-verticals-content-item">
                    <h3>Followone Healthcare</h3>
                    <p>Aims to establish a strong presence in senior care through the development and acquisition of Assisted Living and Adult Family Homes, initially focusing on the Pacific Northwest.</p>
                    {/* <a href="/">Learn More</a> */}
                </div>

                <div className="business-verticals-content-item">
                    <h3>Followone Jobs</h3>
                    <p>Plans to streamline the job recruitment process with company job postings, easy applications, and an application tracking system for job seekers.</p>
                    {/* <a href="/">Learn More</a> */}
                </div>

                <div className="business-verticals-content-item">
                    <h3>Followone Services</h3>
                    <p>Will connect service providers and users through comprehensive listings, easy selection processes, and opportunities for non-profit and volunteer engagement.</p>
                    {/* <a href="/">Learn More</a> */}
                </div>

                <div className="business-verticals-content-item">
                    <h3>Followone Food</h3>
                    <p>Will include restaurant menus, seamless ordering and delivery, booking tools, and local grocery listings, creating a comprehensive food and dining platform.</p>
                    {/* <a href="/">Learn More</a> */}
                </div>

                <div className="business-verticals-content-item">
                    <h3>Followone Travel</h3>
                    <p>Will provide a one-stop solution for booking hotels, flights, car rentals, cruises, and vacation packages.</p>
                    {/* <a href="/">Learn More</a> */}
                </div>

                <div className="business-verticals-content-item">
                    <h3>Followone Social</h3>
                    <p>Empowers users to post blogs, photos, and videos, with plans for a personal social wall that connects businesses and users, fostering a dynamic and interactive community.</p>
                    {/* <a href="/">Learn More</a> */}
                </div>
            </div>
        </div>
    );
};

export default Verticals;