import aboutImage from '../../../../assets/about/market-analysis.jpeg';

const MarketAnalysis = () => {
    return (
        <div id="market-analysis" className="section market-analysis">
            <h2>Market Analysis</h2>

            <img src={aboutImage} alt="Market Analysis" />

            <h3>Target Market</h3>

            <div className="target-market-content">

                <p>Followone.com is designed to cater to a diverse range of industries, including e-commerce, automotive, real estate, food, jobs, services, travel, and healthcare. The platform's target market spans both brick-and-mortar establishments and online businesses, providing acomprehensive marketplace for selling, renting, and offering various products and services.</p>

                <h4>Target Market Segments:</h4>

                <div className="market-analysis-segments">
                    <div className="market-analysis-segment">
                        <h5>E-commerce and Retail:</h5>
                        <p><b>Who:</b> Small to large-scale businesses, individual sellers, and entrepreneurs involved in the sale of both new and used products.</p>
                        <p><b>What:</b> A marketplace for diverse product categories, including electronics, apparel, home goods, and more.</p>
                        <p><b>Where:</b> Online stores, physical retail locations, and independent sellers.</p>
                    </div>

                    <div className="market-analysis-segment">
                        <h5>Automotive:</h5>
                        <p><b>Who:</b> Auto dealerships, individual car sellers, and buyers.</p>
                        <p><b>What:</b> A platform for the sale and purchase of new and used vehicles.</p>
                        <p><b>Where:</b> Both local and international markets.</p>
                    </div>

                    <div className="market-analysis-segment">
                        <h5>Real Estate:</h5>
                        <p><b>Who:</b> Real estate agencies, property managers, developers, and individual sellers.</p>
                        <p><b>What:</b> Commercial and residential properties for sale or rent, including apartments, offices, and new developments.</p>
                        <p><b>Where:</b> Urban and suburban areas, catering to both local and foreign investors.</p>
                    </div>

                    <div className="market-analysis-segment">
                        <h5>Food and Culinary:</h5>
                        <p><b>Who:</b> Restaurants, catering services, food trucks, and grocery stores.</p>
                        <p><b>What:</b> A dynamic marketplace for culinary offerings, ranging from daily meals to large catering orders.</p>
                        <p><b>Where:</b> Urban centers, tourist areas, and food delivery zones.</p>
                    </div>

                    <div className="market-analysis-segment">
                        <h5>Job Market:</h5>
                        <p><b>Who:</b> Companies looking to hire, recruitment agencies, and job seekers.</p>
                        <p><b>What:</b> Employment opportunities across various industries, from entry-level positions to executive roles.</p>
                        <p><b>Where:</b> Nationwide, with a focus on regions with high job market activity.</p>
                    </div>

                    <div className="market-analysis-segment">
                        <h5>Services:</h5>
                        <p><b>Who:</b> Freelancers, professional service providers, contractors, and service-seeking individuals.</p>
                        <p><b>What:</b> A platform for offering and finding various services, including home repair, legal services, consulting, and more.</p>
                        <p><b>Where:</b> Local and regional markets, with an emphasis on urban areas with high demand for specialized services.</p>
                    </div>

                    <div className="market-analysis-segment">
                        <h5>Travel:</h5>
                        <p><b>Who:</b> Travel agencies, hotels, car rental services, and property owners offering vacation rentals. </p>
                        <p><b>What:</b> A platform for booking travel packages, accommodations, car rentals, and vacation homes.</p>
                        <p><b>Where:</b> Tourist destinations, popular travel routes, and emerging markets.</p>
                    </div>

                    <div className="market-analysis-segment">
                        <h5>Healthcare:</h5>
                        <p><b>Who:</b> Adult family homes, healthcare providers, insurance agents, and medical supply companies.</p>
                        <p><b>What:</b> Services and products related to elderly care, medical supplies, and healthcare facilities.</p>
                        <p><b>Where:</b> Residential areas with a significant elderly population, urban centers with a concentration of healthcare facilities.</p>
                    </div>
                </div>

            </div>

            <h3>Demographics</h3>

            <div className="demographics-content">

                <p><b>Age:</b> 18-65 years old, with a focus on working professionals, business owners, and individuals
                in mid-to-upper income brackets.</p>

                <p><b>Income Level:</b> Low to high-income individuals and families, businesses ranging from startups
                to established enterprises.</p>

                <p><b>Geographic Location:</b> Primarily urban and suburban areas, with expansion potential in
                emerging markets and rural areas with growing e-commerce and service needs.</p>

                <p><b>Tech-Savviness:</b> Users familiar with online platforms, digital marketplaces, and mobile
                applications, with a preference for convenient, all-in-one solutions.</p>

            </div>

            <h3>Competitve Landscape</h3>

            <div className="competitive-landscape-content">

                <p>Within each business vertical, Followone faces competition from leading businesses. We believe that knowing our rivals gives us important insights into the workings of the market and draws attention to the distinctive value offer that makes Followone stand out.</p>

                <p><b>Followone Auto:</b> The automotive sector features established platforms like CarMax, Car Gurus, and Auto Trader. While these platforms have a presence, Followone intends to compete to attract car dealers by allowing the dealer to post all its inventory and more such as: job opportunities, events, photo galleries, videos, services and even products on their profile page.</p>

                <p><b>Followone Real Estate:</b> In the real estate realm, Zillow, Redfin, and LoopNet are recognized contenders. Followone intends to distinguish itself by providing both residential, commercial for rent, lease or buy.</p>

                <p><b>Followone Store:</b> Followone Store operates in a competitive landscape with platforms such as OfferUp, eBay and Doba. What we believe sets our platform apart is the innovative “store” view feature on the profile page, which allows both businesses and consumers to showcase an array of products. This user-friendly interface is further enhanced by our efficient search functionality, along with a comprehensive breakdown into categories and subcategories, simplifying the process for users to find specific products. Additionally, Followone Store proudly offers an extensive selection of over 3 million drop shipping products across more than 100 categories, sourced from a variety of vendors in China, thereby providing a wide range of options for our users.</p>

                <p><b>Followone Jobs:</b> The job market is intensely competitive, with major players like LinkedIn, Monster, and Indeed leading the way. Followone Jobs intends to set itself apart from these competitors by offering a unique pricing model where businesses are not charged per post. Businesses have the flexibility to advertise multiple job openings. Additionally, Followone is in the process of introducing a range of tools designed to help businesses manage applicants more effectively and better communication tools.</p>

                <p><b>Followone Travel:</b> Our competitors in this space include Expedia and Airbnb. Our travel vertical is under development currently. We plan to compete in this space by the end of 2027.</p>

                <p><b>Followone Healthcare:</b> Our competitors in this space include Ventas and Welltower. Our healthcare vertical is under development currently. We plan to compete in this space by the end of 2025.</p>

            </div>

            <h3>Market Opportunity</h3>

            <div className="market-opportunity-content">

                <p>Our Company operates within a diverse market that encompasses a wide range of businesses and individual consumers across various verticals:</p>

                <ul>
                    <li>In the realm of e-commerce, our market base extends to both brick-and-mortar and e-commerce stores, catering to individuals selling an array of products, whether new or used.</li>
                    <li>The automotive sector involves engagement with auto dealerships and individual sellers, covering the spectrum of new and used cars.</li>
                    <li>In the real estate domain, our reach extends to agencies and individuals involved in the selling or renting of both commercial and residential properties.</li>
                    <li>The planned food sector involves collaboration with restaurants, business owners, and catering services, facilitating a dynamic marketplace for culinary offerings.</li>
                    <li>The planned job market encompasses both companies offering employment opportunities and individuals actively seeking job opportunities.</li>
                    <li>Services, another planned integral vertical, involves collaboration with companies and individuals providing various services, as well as those in search of such services.</li>
                    <li>The planned travel sector broadens our scope to include companies offering travel packages, hotels, car rentals, and individual property owners providing vacation homes for rental.</li>
                    <li>Lastly, our engagement in the planned healthcare sector involves interactions with adult family homes providing elderly care services and companies specializing in the sale of medical supplies.</li>
                </ul>

                <p>In essence, our Company's market and customer base are vast and diverse, spanning businesses and individuals within the realms of e-commerce, automotive, real estate, food, jobs, services, travel, and healthcare. This wide-ranging approach underscores our commitment to providing a comprehensive platform that caters to the multifaceted needs of both businesses and individual consumers across various industries.</p>
            </div>
        </div>
    );
};

export default MarketAnalysis;