import businessModelImage from '../../../../assets/about/business-model.jpeg';

const BusinessModel = () => {
    return (
        <div id="business-model" className="section business-model">
            <h2>Business Model</h2>

            <img src={businessModelImage} alt="Business Model" />

            <h3>Business Strategy</h3>

            <div className="business-strategy-content">

                <p>Our ambition to make the world a better place to live, is still in its early stages. We think there's a big chance we can improve the value we offer to businesses, enterprises, and users. Important components of our approach are:</p>

                <p><b>Create Strong Connections.</b> Our business strategy is a flexible and adaptable one that works across multiple industries including Business-to-Business (B2B), Business-to-Enterprise (B2E), Business-to-Consumer (B2C), and Consumer-to-Consumer (C2C). By engaging with diverse audiences, we aim to create a comprehensive ecosystem that caters to the unique needs of each segment.</p>
                
                <p><b>Design simple and effective mobile and desktop experiences</b>, catering to the comprehensive needs of users throughout their lifetime. This approach ensures that users have seamless access to a wide range of services and products they regularly use, spend on, or need in various stages of their life.</p>

                <p>By integrating such a diverse array of offerings into a single, easy-to-navigate platform, we believe that Followone significantly simplifies the user experience. Whether it's shopping, accessing services, or engaging with communities, users can effortlessly find and utilize what they need across devices.</p>

                <p>We believe that this thoughtful design not only enhances convenience but also adds value to the daily lives of users by streamlining their online activities and interactions, making Followone an indispensable tool in their digital arsenal.</p>

                <p>Improve Revenue Model / Sustainable Value. Within our model, we offer a range of services, including advertising, software licensing and strategic mergers and acquisitions. These elements synergistically contribute to the expansion and growth of our verticals.</p>

                <ul>
                    <li>Through targeted advertising, we seek to connect businesses with their target audiences, fostering mutually beneficial relationships.</li>
                    <li>Our ability to commercialize our exclusive technology through software licensing will allow other businesses to take use of our advanced solutions.</li>
                    <li>We believe that additionally, our strategic approach to mergers and acquisitions allows us to diversify our offerings and enter new markets, further solidifying our position as an industry leader.</li>
                </ul>

                <p>This multifaceted business model reflects our commitment to adaptability and innovation in the ever-evolving landscape of commerce. By addressing the diverse needs of B2B, B2E, B2C, and C2C interactions and incorporating advertising, and strategic acquisitions, we believe that we position ourselves for sustained success and growth across various verticals.</p>

                <p><b>Improve our Advertising Platform for Users and Businesses.</b> Future plans call for a number of improvements to our advertising platform, mostly aimed at giving our marketers and advertisers access to a wider selection of goods.</p>

                <p>We are dedicated to striking a balance between reaching our revenue targets and consistently enhancing the user experience as we move forward with these innovations. This strategy makes sure that even as we improve our advertising capabilities, we don't lose sight of our commitment to provide people the best possible experience.</p>
            
            </div>

            <h3>Revenue Models</h3>

            <div className="revenue-models-content">

                <p>The Company’s growth strategy is a multichannel approach that incorporates four main channels intending to spur growth and create a strong brand presence: advertising platform, software licensing and merger & acquisitions.</p>

                <p><b>Advertising Platform:</b> We believe that the advertising platform is a vital component of income creation since it offers businesses the chance to maximize their visibility through guaranteed pay-per-view (GPPV), sponsored, and featured ads. We intend to continuously improve the advertising platform by adding functions like location-based advertising and modifiable audience targeting. With the use of this tactic, companies and users should be able to maximize their advertising campaigns to boost engagement and sales.</p>

                <p><b>Software Licensing:</b> Our software licensing model involves offering our technology to other businesses, aiming to create a new revenue stream. This model aims to leverage the strength of our technology and its potential applications across various industries. We will explore partnerships and collaborations with businesses interested in integrating our technology into their operations. Licensing agreements will be structured to result in a mutually beneficial arrangement, expanding the reach of Followone's technology. At this time the Company does not have any licensing agreements in place.</p>

                <p><b>Merger and Acquisitions:</b> We believe that strategic mergers and acquisitions are integral to our growth strategy, enabling us to expand our footprint, user base, and service offerings. Rigorous market research will identify potential targets for acquisition. Followone will engage in strategic negotiations, partnerships, and acquisitions, with a focus on easy integration and mutual benefits. Additionally, we will explore opportunities to acquire businesses that align with our vision and objectives.</p>

            </div>

            <h3>New Market Penetration</h3>

            <div className="new-market-penetration-content">

                <p>In the coming years, Followone is poised for significant market penetration across various sectors, expanding its reach and influence globally. By the end of 2025, <b>Followone Real Estate</b> plans to make a substantial move by entering nine key U.S. states: California, Texas, Florida, New York, Illinois, Arizona, Michigan, Oregon, and Idaho. This expansion will be a critical step in establishing Followone as a major player in the U.S. real estate market, offering comprehensive residential and commercial property listings tailored to the needs of these diverse and economically significant regions.</p>

                <p>Following this, by the end of 2026, <b>Followone Auto</b> plans to begin its international expansion by incorporating auto dealers from Europe. This strategic move will broaden the platform's vehicle offerings and introduce European models to a wider audience, catering to both local and international buyers. This European expansion marks the beginning of Followone Auto's journey towards becoming a truly global automotive marketplace.</p>

                <p>As part of its long-term vision, by the end of 2027, <b>Followone Real Estate</b> plans to have completed its nationwide U.S. expansion, services in all 52 states. This nationwide presence will solidify Followone's position as a leading real estate platform, capable of serving a diverse range of customers across the entire country. Additionally, <b>Followone Travel</b> will be launched, providing users with a comprehensive travel booking platform that includes hotel, flight, and rental services, further diversifying Followone's market presence.</p>

                <p>Looking towards 2030, <b>Followone Auto</b> plans to achieve a major milestone by expanding its dealer network to include partners from across the globe. This global expansion will provide users with access to a vast array of vehicles from different markets, making Followone Auto a go-to platform for international vehicle purchases. This move will cement Followone's status as a global leader in the automotive industry, offering unparalleled access to a diverse range of vehicles worldwide.</p>

            </div>

            <h3>Product Development Roadmap</h3>

            <div className="product-development-roadmap">
 
                <div className="product-development-roadmap-item">
                    <div className="pdri-year">2025</div>
                    <div className="pdri-description">

                        <h4>Followone Platform</h4>
                        <ul>
                            <li>Release of Followone v2.0</li>
                            <li>Initial AI Integration Rollout</li>
                        </ul>

                        <h4>Followone Social</h4>
                        <ul>
                            <li>Videos</li>
                        </ul>

                        <h4>Followone Auto</h4>
                        <ul>
                            <li>Ability to Schedule Appointments</li>
                            <li>Submit Trade-In Details</li>
                            <li>Finance Pre-Approval</li>
                        </ul>

                        <h4>Followone Real Estate</h4>
                        <ul>
                            <li>Expand to California, Texas, Florida, New York, Illinois, Arizona, Michigan, Oregon, Idaho</li>
                        </ul>

                        <h4>Followone Healthcare</h4>
                        <ul>
                            <li>Initial Rollout</li>
                        </ul>

                        <h4>Followone Services</h4>
                        <ul>
                            <li>Improvements</li>
                        </ul>
                    </div>
                </div>

                <div className="product-development-roadmap-item">
                    <div className="pdri-year">2026</div>
                    <div className="pdri-description">
                        <h4>Followone Auto</h4>
                        <ul>
                            <li>Add dealers from Europe</li>
                        </ul>

                        <h4>Followone Store</h4>
                        <ul>
                            <li>Shopping Cart for verified US businesses</li>
                        </ul>

                        <h4>Followone Jobs</h4>
                        <ul>
                            <li>Job Application Process</li>
                            <li>Application Tracking System</li>
                        </ul>

                        <h4>Followone Foods</h4>
                        <ul>
                            <li>Initial Rollout</li>
                        </ul>

                        <h4>Followone Social</h4>
                        <ul>
                            <li>Personalized Wall</li>
                            <li>Groups</li>
                        </ul>
                    </div>
                </div>

                <div className="product-development-roadmap-item">
                    <div className="pdri-year">2027</div>
                    <div className="pdri-description">
                        <h4>Followone Real Estate</h4>
                        <ul>
                            <li>Expand across entire 52 state</li>
                        </ul>

                        <h4>Followone Travel</h4>
                        <ul>
                            <li>Initial Rollout</li>
                            <li>Short Term Rentals with Full Booking System</li>
                        </ul>
                    </div>
                </div>

                <div className="product-development-roadmap-item">
                    <div className="pdri-year">2030</div>
                    <div className="pdri-description">
                        <h4>Followone Auto</h4>
                        <ul>
                            <li>Add dealers from across the globe</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessModel;