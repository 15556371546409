import ImgBenCategory from '../../../../assets/static/about-benefits-category.jpg'
import ImgBenHome from '../../../../assets/static/about-benefits-home.jpg'
import ImgBenListingDetailPage from '../../../../assets/static/about-benefits-listing-detail-page.jpg'
import ImgBenListingDetailSidebar from '../../../../assets/static/about-benefits-listing-detail-sidebar.jpg'
import ImgBenListingPreview from '../../../../assets/static/about-benefits-listing-preview.jpg'
import ImgBenProfileListingPage from '../../../../assets/static/about-benefits-profile-listing-page.jpg'
import ImgBenProfilePage from '../../../../assets/static/about-benefits-profile-page.jpg'
import ImgBenInterfaceDesign from '../../../../assets/about/interface-design.jpeg'

import FollowoneLogo from '../../../../assets/about/brand-followone-com.svg'
import LogoAuto from '../../../../assets/about/verticals/auto.svg'
import LogoRealEstate from '../../../../assets/about/verticals/realestate.svg'
import LogoStore from '../../../../assets/about/verticals/store.svg'
import LogoHealthcare from '../../../../assets/about/verticals/healthcare.svg'
import LogoTravel from '../../../../assets/about/verticals/travel.svg'
import LogoJobs from '../../../../assets/about/verticals/jobs.svg'
import LogoServices from '../../../../assets/about/verticals/services.svg'
import LogoFood from '../../../../assets/about/verticals/foods.svg'
import LogoSocial from '../../../../assets/about/verticals/social.svg'

import ImgAutoPreview from '../../../../assets/about/previews/auto.png'
import ImgRealEstatePreview from '../../../../assets/about/previews/realestate.png'
import ImgStorePreview from '../../../../assets/about/previews/store.png'
import ImgTravelPreview from '../../../../assets/about/previews/travel.png'
import ImgJobsPreview from '../../../../assets/about/previews/jobs.png'
import ImgServicesPreview from '../../../../assets/about/previews/services.png'
import ImgFoodPreview from '../../../../assets/about/previews/foods.png'

import ImgZaklist from '../../../../assets/about/brand-zaklist.svg'
import ImgCarverve from '../../../../assets/about/brand-carverve.svg'
import ImgCarzlook from '../../../../assets/about/brand-carzlook.svg'
import AccreditedLogo from '../../../../assets/about/accredited.svg'

import ImgHealthcarePreview from '../../../../assets/about/healthcare.jpeg'
import socialVideo from '../../../../assets/about/social-video.mov'

const FollowoneCom = () => {
    return (
        <div id="followone-com" className="section followone-com">
            <div className="followone-com-cover">
                <img src={FollowoneLogo} alt="Followone.com" />

                <div className="followone-apps-download">
                    <a href="" className="app-download-button">
                        <i className="fab fa-apple"></i>
                        <span>
                            <small>Download on the</small>
                            App Store
                        </span>
                    </a>

                    <a href="" className="app-download-button">
                        <i className="fab fa-google-play"></i>
                        <span>
                            <small>Get it on</small>
                            Google Play
                        </span>
                    </a>
                </div>
            </div>

            <div className="followone-com-intro">
                <p>At Followone, we envision a diverse ecosystem where each vertical represents a unique busi-ness entity, crafted to address speciﬁc consumer needs. Our strategic approach involves signiﬁcant investments and dedicated resources to develop and reﬁne each vertical. </p>

                <p>Several verticals have already been developed and are currently active on Followone.com and the Followone Mobile Apps which are available via Android through the Google Play Store and iOS through the App Store. However, it's important to note that our roadmap encompasses ongoing enhancements and future features for each of these platforms. Our commitment to continuous improvement aims to ensure that users can anticipate a dynamic and evolving experience across all these active verticals. </p>
            </div>

            <div className="followone-com-vertical-list">

                <h3>Our active business verticals: </h3>

                <div className="followone-com-vertical-list-item">
                    <i className="fa-thin fa-car bkg-auto"></i>
                    <p>
                        <span className="title">Followone Auto</span>
                        <span className="focus">About 6M Vehicles (live) from 108k Auto Dealers</span>
                        <span className="description">We currently provide vehicles from all U.S., Canada & Europe (Romania). Listings from other European countries coming soon.</span>
                    </p>
                </div>

                <div className="followone-com-vertical-list-item">
                    <i className="fa-thin fa-building bkg-realestate"></i>
                    <p>
                        <span className="title">Followone Real Estate Inc.</span>
                        <span className="focus">About 500k Listings</span>
                        <span className="description">We currently provide commercial and residential properties from U.S. (Washington, Oregon & Idaho) Listings from other 48 states coming soon. </span>
                    </p>
                </div>

                <div className="followone-com-vertical-list-item">
                    <i className="fa-thin fa-shopping-bag bkg-store"></i>
                    <p>
                        <span className="title">Followone Store</span>
                        <span className="focus">About 3M Products (from Followone Outlets)</span>
                        <span className="description">Listings will include diverese categories such as: apparel, toys, electronics and more. </span>
                    </p>
                </div>

                <div className="followone-com-vertical-list-item">
                    <i className="fa-thin fa-hand-holding-heart bkg-healthcare"></i>
                    <p>
                        <span className="title">Followone Healthcare</span>
                        <span className="focus">Assisted Living Centers & Adult Family Homes (AFH) </span>
                        <span className="description">Aims to establish a strong presence in senior care initially focusing on the Paciﬁc Northwest.</span>
                    </p>
                </div>

                <h3>Business verticals under development: </h3>

                <div className="followone-com-vertical-list-item">
                    <i className="fa-thin fa-bed bkg-travel"></i>
                    <p>
                        <span className="title">Followone Travel</span>
                        <span className="focus">Book flights, hotels & more.</span>
                        <span className="description">This platform will compete with Expedia, Airbnb, Orbitz etc.</span>
                    </p>
                </div>

                <div className="followone-com-vertical-list-item">
                    <i className="fa-thin fa-briefcase bkg-jobs"></i>
                    <p>
                        <span className="title">Followone Jobs</span>
                        <span className="focus">Posting job offers & more.</span>
                        <span className="description">Followone Jobs will grow allowing users to find their ne xt career.</span>
                    </p>
                </div>

                <div className="followone-com-vertical-list-item">
                    <i className="fa-thin fa-wrench bkg-services"></i>
                    <p>
                        <span className="title">Followone Services</span>
                        <span className="focus">Posting services on over 100 categories.</span>
                        <span className="description">Service offers are in high demand, and F ollowone is thrilled to focus on this platform.</span>
                    </p>
                </div>

                <div className="followone-com-vertical-list-item">
                    <i className="fa-thin  fa-utensils bkg-food"></i>
                    <p>
                        <span className="title">Followone Foods</span>
                        <span className="focus">Posting menus, booking tables & more.</span>
                        <span className="description">Users will be able to quickly find and order their fa vorite foods, and also find local grown foods.</span>
                    </p>
                </div>
            </div>

            <div className="followone-com-auto">
                <div className="followone-com-logo">
                    <img src={LogoAuto} alt="Followone Auto" />
                </div>

                <div className="followone-com-preview" style={{ backgroundImage: `url(${ImgAutoPreview})` }}></div>

                <div className="followone-com-content">

                    <p>Followone Auto  aims to offer a unique value proposition for car dealers, seeking to enhance their business visibility and customer engagement through a single account. With this account, dealers gain a dedicated landing page on Followone where their entire inventory is easily accessible and browsable. This feature alone signiﬁcantly streamlines the customer experience. Beyond this, Followone extends the reach of these listings by offering the option to display the dealer's content across three additional platforms, effectively quadrupling the exposure for each car listing. But the beneﬁts don't stop at inventory visibility. The same account allows dealers to promote various aspects of their business – from service offerings and job openings to special sale events. This multi-faceted approach not only aims to boost their marketing efforts but also seeks to aid in building a community of followers, deepening relationships with existing customers while attracting new ones. We believe that by leveraging Followone, car dealers can signiﬁcantly amplify their online presence, diversify their marketing strategies, and foster a loyal customer base, all within a single, cohesive ecosystem.</p>

                    <p><b>Empowering Auto Dealer Businesses:</b> For auto dealer businesses, Followone Auto enables them to present their entire inventory across four platforms: Followone.com/Autos, Carverve.com, Carzlo-ok.com, and Zaklist.com, providing dealers broad visibility to diverse audiences. This approach aims to ensure an easy-to-navigate experience for users, facilitating efﬁcient communication with dealers through chat, click-to-call, or directions to the dealership. We plan to introduce no later than the end of 2025 features such as scheduling appointments, submitting trade-in details, and the added convenience of pre-approval for ﬁnancing.</p>

                    <p><b>Streamlining Individual Car Sales:</b> Our commitment extends to individuals worldwide looking to sell their cars, providing a user-friendly platform for showcasing their vehicles. Whether it is a local or international car owner, Followone seeks to make it effortless for individuals to display their vehicles for sale.</p>

                    <p><b>Vast Consumer Choices:</b> Followone Auto offers consumers an extensive and diverse selection, with access to over 108,000 active dealers and an average of 6 million vehicles spanning both the United States and Canada. The platform aims to simplify and enrich the automotive journey, recognizing its signiﬁcance in consumers' lives. In our strategic roadmap, we envision a robust expansion plan that involves the addition of dealers from Europe by the end of 2026 and from across the globe by 2030. This initiative aims to diversify and enrich our dealer network, providing users with a broader selec-tion and global accessibility.</p>

                </div>

                <div className="followone-com-auto-benefits">

                    <h2>DEALER MEMBERSHIP BENEFITS</h2>
                    <p>Full access to our dedicated auto platforms on web, Android and iOS.</p>
                    

                    <div className="followone-com-auto-brands">
                        <img src={ImgZaklist} alt="ZakList" />
                        <img src={ImgCarverve} alt="Carverve" />
                        <img src={ImgCarzlook} alt="Carzlook" />
                    </div>

                    <div className="followone-com-auto-benefits-list">
                        <div className="followone-com-auto-benefits-item">
                            <i className="fal fa-car"></i>
                            <p>
                                <span className="title">FOCUS ON SALES</span>
                                <span className="description">We manange dealer inventory on Followone, ZakList, CarzLook and CarVerve as part of the dealer membership.</span>
                            </p>
                        </div>

                        <div className="followone-com-auto-benefits-item">
                            <i className="fal fa-search"></i>
                            <p>
                                <span className="title">INCREASE FINDABILITY</span>
                                <span className="description">Listings show up organically on Google, Bing and Yahoo search.</span>
                            </p>
                        </div>

                        <div className="followone-com-auto-benefits-item">
                            <i className="fal fa-link"></i>
                            <p>
                                <span className="title">IMPROVE SEO</span>
                                <span className="description">Linking back to dealer’s website improves Search Engine Optimization.</span>
                            </p>
                        </div>

                        <div className="followone-com-auto-benefits-item">
                            <i className="fal fa-map-marker-alt"></i>
                            <p>
                                <span className="title">LOCAL EXPOSURE</span>
                                <span className="description">Our focus is to bring local customers.</span>
                            </p>
                        </div>

                        <div className="followone-com-auto-benefits-item">
                            <i className="fal fa-tags "></i>
                            <p>
                                <span className="title">PROMOTE DEALS </span>
                                <span className="description">Add special deals to products and services at no extra cost.</span>
                            </p>
                        </div>

                        <div className="followone-com-auto-benefits-item">
                            <img className="accredited-logo" src={AccreditedLogo} alt="Accredited" />
                            <p>
                                <span className="title">BOOST CREDIBILITY</span>
                                <span className="description">Followone Accredited logo available on proﬁle page and as a window sticker.</span>
                            </p>
                        </div>

                        <div className="followone-com-auto-benefits-item">
                            <i className="fal fa-id-card"></i>
                            <p>
                                <span className="title">ONE LOGIN</span>
                                <span className="description">One login for all websites: same user ID and Password.</span>
                            </p>
                        </div>

                        <div className="followone-com-auto-benefits-item">
                            <i className="fal fa-camera"></i>
                            <p>
                                <span className="title">CONNECT WITH CLIENTS</span>
                                <span className="description">Enrich the proﬁle page with photos, videos, blogs & events.</span>
                            </p>
                        </div>

                        <div className="followone-com-auto-benefits-item">
                            <i className="fal fa-users"></i>
                            <p>
                                <span className="title">BUILD RELATIONSHIPS</span>
                                <span className="description">Turn customers into followers.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="followone-com-real-estate">
                <div className="followone-com-logo">
                    <img src={LogoRealEstate} alt="Followone Real Estate" />
                </div>

                <div className="followone-com-preview" style={{ backgroundImage: `url(${ImgRealEstatePreview})` }}></div> 

                <div className="followone-com-content">

                    <p>Followone Real Estate intends to stand out as a highly beneﬁcial platform for real estate agents, offering a streamlined, efﬁcient solution for managing diverse property listings. With just one login, agents gain access to a wide array of properties, encompassing residential, commercial, and business listings. This uniﬁed access simpliﬁes the process for agents, allowing them to handle various types of properties from a single, user-friendly interface, thereby saving time and increasing productivity. For users, particularly homeowners, Followone seeks to offer a unique advantage by providing free listings for selling or renting their properties directly, without requiring an agent. This feature not only empowers homeowners to take control of their property transactions but also opens up a broader market for potential buyers and renters. By catering to the needs of both real estate profes-sionals and property owners, Followone Real Estate aims to effectively bridge the gap in the proper-ty market, offering a versatile, cost-effective, and accessible platform for various real estate needs. At this time, we currently provide residential and commercial real estate services in Washington State and commercial services in Oregon and Idaho. </p>

                    <p>Residential Real Estate: Followone Real Estate provides a comprehensive platform for residential properties. Users have the ﬂexibility to browse through a variety of residential listings, whether under the management of agencies or offered directly by owners, providing options for buying, selling, or renting properties. Notably, properties sold by agencies are currently available only from Washington State, with plans to expand to other US states targeting in the second phase: California, Texas, Florida, New York Illinois, Arizona, Michigan, Oregon, Idaho by the end of 2025 and third phase, nationwide, no later than 2027. </p>

                    <p>Commercial Real Estate: Our platform extends its offerings to commercial real estate, catering to businesses and entrepreneurs looking to buy, sell, or lease properties. While agency listings are available from Washington State, Oregon, and Idaho, we have plans to expand nationwide.</p>

                    <p>Business Opportunities: Followone Real Estate opens doors to promising business opportunities, currently showcasing listings from Washington State, with plans to expand nationwide. Whether presented by agencies or individual business owners, users can explore a spectrum of options for starting or expanding their ventures.</p>

                    <p>Followone Real Estate Inc. has a real estate brokerage license in the following states*:</p>

                    <table>
                        <tbody>
                            <tr>
                                <td>STATE</td>
                                <td>LICENSE NO.</td>
                            </tr>
                            <tr>
                                <td>Washington</td>
                                <td>20117919</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>*Coming soon in all U.S. States.</p>

                </div>
            </div>

            <div className="followone-com-store">
                <div className="followone-com-logo">
                    <img src={LogoStore} alt="Followone Store" />
                </div>

                <div className="followone-com-preview" style={{ backgroundImage: `url(${ImgStorePreview})` }}></div> 

                <div className="followone-com-content">

                    <p>Followone Store aims to offer a dynamic and value-driven platform for both businesses and individ-ual users. For businesses, the Followone account opens up an opportunity to create a vast online showroom. By utilizing their proﬁle page, businesses can showcase a large inventory of products, effectively building their digital presence and reaching a wider audience. This feature acts as a virtual extension of their physical storefront, providing a comprehensive and accessible browsing experience for customers. Individual users, on the other hand, beneﬁt from the ability to list prod-ucts for sale at no extra cost. This aspect of the platform caters to a variety of needs, whether it is selling unused items locally or offering new products. Moreover, all users will have access to a multi-tude of discounted products available for purchase through Followone, a feature that is currently in development. This anticipated addition promises to further enrich the user experience by providing an extensive range of products at competitive prices, making Followone Store an all-encompassing marketplace for diverse shopping needs. Through the Followone Store, the Company intends to connect buyers and sellers and also offer items for sale directly to purchasing users via drop-ship-ping. </p>

                    <p>Local Buying and Selling on Followone: Followone's ecommerce vertical, allows businesses and individual users worldwide to seamlessly sell and purchase products locally. Our platform caters to the diverse needs of businesses while ensuring a smooth shopping experience for end-users. Cur-rent features empower users to list and sell products, with upcoming development of shopping cart enhancements designed exclusively for veriﬁed businesses. </p>

                    <p>Global Drop Shipping with Followone Store Currently in Development: The e-commerce store is designed for global accessibility. Consumers can explore an extensive product catalog of about 3 million products sourced directly from China's expansive market. The dropship model enables collaboration with multiple vendors, offering a diverse range of products to suit various consumer preferences at low prices. As we advance in the development of Followone Store, our unwavering commitment to convenience and affordability remains at the forefront, creating a unique online shopping destination.</p>

                    <p>Upcoming Feature by the end of 2026: Shopping Cart for Veriﬁed US Businesses: This enhance-ment ensures a streamlined purchasing process, reﬂecting our dedication to providing businesses and consumers with an efﬁcient online commerce environment. </p>
                    
                </div>
            </div>

            <div className="followone-com-healthcare">
                <div className="followone-com-logo">
                    <img src={LogoHealthcare} alt="Followone Healthcare" />
                </div>

                <img className="img-full" src={ImgHealthcarePreview} alt="Healthcare Vertical" />

                <div className="followone-com-content">
                    <p>Some of our directors encompass a wealth of experience, collectively accumulating over 20 years in the healthcare industry, marked by a track record of owning and successfully growing healthcare businesses. We are conï¬dent that their insights will play a pivotal role in steering our healthcare business. As we strive for excellence and innovation in the healthcare vertical, their leadership will be instrumental in shaping Followone's position in the evolving landscape of healthcare services.</p>

                    <p>Followone Healthcare has strategic plans to establish a strong presence in the senior care sector by owning and operating facilities dedicated to Assisted Living and Adult Family Homes (AFH). In pursuit of this vision, we are exploring opportunities through developing our own, merger & acquisi-tions, as well as potential joint venture agreements. The objective is to provide a high level of profes-sional care to seniors in the Paciﬁc Northwest region initially, with future expansion plans nation-wide.</p>

                    <p>Assisted Living: Within the realm of Assisted Living, Followone Healthcare envisions developing our own facilities that offer personalized care and support for seniors. We will aim to provide a comfortable and enriching environment for residents.</p>

                    <p>Adult Family Homes (AFH): Followone Healthcare is also focused on the Adult Family Homes sector, aiming to own and operate residences that cater to the unique needs of seniors. By fostering a home-like atmosphere and ensuring personalized care, we intend to make a positive impact on the quality of life for individuals in our care.</p>

                    <p>Provider Listings for Spaces and Facilities: Followone Healthcare is intended to serve as a plat-form for healthcare providers to list available spaces and facilities, accompanied by detailed busi-ness information. This feature is intended to enable users to connect with local providers seamless-ly, facilitating the discovery of suitable facilities for their loved ones' speciﬁc needs.</p>

                    <p>Healthcare Product Listings: Our platform will aim to accommodate healthcare companies, allow-ing them to showcase their range of healthcare products. This feature creates a centralized space for users to explore and access a diverse array of healthcare-related products, fostering conve-nience and accessibility in the healthcare industry.</p>

                    <p>We plan to roll out Followone Healthcare by the end of 2025.</p>
                </div>
            </div>

            <div className="followone-com-travel">
                <div className="followone-com-logo">
                    <img src={LogoTravel} alt="Followone Travel" />
                </div>

                <div className="followone-com-preview" style={{ backgroundImage: `url(${ImgTravelPreview})` }}></div> 

                <div className="followone-com-content">
                    <p>Followone Travel is part of a planned future release with an array of exciting, planned features as noted below.</p>

                    <p>Hotel Bookings: In the future, Followone Travel will empower users to book stays at hotels. This feature aims to provide travelers with a diverse range of accommodation options, ensuring a com-fortable and enjoyable stay.</p>

                    <p>Upcoming Flight Bookings: Followone Travel's future features will enable users to easily book ﬂights, creating a one-stop platform for all their travel needs.</p>

                    <p>Car Rentals (Businesses and Individuals): Followone Travel envisions a future where users can rent cars from both businesses and individuals. This feature will offer ﬂexibility and convenience for travelers looking to explore their destination at their own pace.</p>

                    <p>Cruise Exploration: As part of our future development plans, Followone Travel will introduce features allowing users to explore and book cruises. This upcoming addition will beneï¬t those seek-ing unique and memorable maritime adventures.</p>

                    <p>Vacation and Travel Package Deals: Followone Travel's future roadmap includes the introduction of vacation and travel package deals. Users can look forward to accessing curated packages that com-bine ﬂights, accommodations, and other travel essentials for a hassle-free and cost-effective travel experience.</p>

                    <p>Upcoming Feature - Short-Term Rent with Full Booking System: In our commitment to innovation, Followone Travel is diligently working on an upcoming feature that will aim to revolutionize short-term rentals. This development aims to introduce a comprehensive booking system, allowing real estate owners to easily manage short-term rental properties. This forward-thinking addition aligns with our dedication to staying at the forefront of user needs and preferences in the evolving real estate landscape. We plan to release it to public by the end of 2027.</p>

                    <p>We plan to roll out Followone Travel by the end of 2027.</p>
                </div>
            </div>

            <div className="followone-com-jobs">
            <div className="followone-com-logo">
                    <img src={LogoJobs} alt="Followone Jobs" />
                </div>

                <div className="followone-com-preview" style={{ backgroundImage: `url(${ImgJobsPreview})` }}></div> 

                <div className="followone-com-content">

                    <p>Followone Jobs is designed as a dynamic platform intended to streamline the job recruitment process, focusing on both companies and job seekers. Key features are planned to include:</p>

                    <p>Job Posting for Companies: this will empower companies to post job opportunities and reach a wide audience of potential candidates. Whether seeking professionals, skilled workers, or entry-level positions, our platform will offer a user-friendly interface for companies to showcase their job openings.</p>

                    <p>User-friendly Job Application Process: Job seekers will be able to easily browse and apply for diverse job opportunities available on Followone Jobs. The platform will provide an efﬁcient applica-tion process, allowing users to submit their resumes and relevant information directly to the hiring companies.</p>

                    <p>Application Tracking System: Followone Jobs will go beyond basic job listings by incorporating an Application Tracking System. This feature will allow companies to efﬁciently manage and track job applications, streamlining the recruitment workﬂow. It will enable companies to review, categorize, and communicate with applicants, enhancing the overall hiring experience.</p>

                    <p>By offering a comprehensive solution for both companies and job seekers, Followone Jobs aims to contribute to a more efﬁcient and transparent job market, facilitating connections between employ-ers and potential employees.</p>

                    <p>This platform is in use now, with limited features. We plan to roll out the Job Application Process and Application Tracking System by the end of 2026.</p>
                </div>
            </div>

            <div className="followone-com-services">
            <div className="followone-com-logo">
                    <img src={LogoServices} alt="Followone Services" />
                </div>
                
                <div className="followone-com-preview" style={{ backgroundImage: `url(${ImgServicesPreview})` }}></div> 

                <div className="followone-com-content">

                    <p>Followone Services will be a platform that seeks to cater to a diverse range of service providers and users, enhancing accessibility and connectivity. The key features are planned to include:</p>

                    <p>Comprehensive Service Listings: Businesses and individuals will leverage Followone Services to list their offerings across more than 100 categories. Whether it's professional services, personal assis-tance, or creative endeavors, the platform accommodates a broad spectrum of service providers.</p>

                    <p>User-Friendly Selection Process: Users will be able to easily explore and select services based on various criteria, such as ratings, reviews, prices, and more. Followone Services is designed to provide a user-friendly interface that facilitates informed decision-making, ensuring users ﬁnd the best-suit-ed services to meet their needs.</p>

                    <p>Non-Proﬁt and Volunteer Opportunities: In addition to commercial services, Followone Services will extend its reach to non-proﬁt organizations and volunteer opportunities. This feature encourag-es community engagement, allowing users to explore and contribute to various social causes and initiatives.</p>

                    <p>By offering a comprehensive range of services and fostering community engagement, Followone Services will aim to be a hub for connections between service providers and users, contributing to a vibrant and dynamic service marketplace.</p>

                    <p>Followone Service is in use now, and we plan on releasing more features through 2025.</p>

                </div>
            </div>

            <div className="followone-com-foods">
                <div className="followone-com-logo">
                    <img src={LogoFood} alt="Followone Foods" />
                </div>

                <div className="followone-com-preview" style={{ backgroundImage: `url(${ImgFoodPreview})` }}></div> 

                <div className="followone-com-content">

                    <p>Followone Foods will bring upcoming features that focus on the following key points:</p>

                    <p>Restaurant Menu Listings: Followone Foods will aim to empower restaurants to showcase their future menu items, creating an extensive online catalog for users to explore. We believe that this feature will enhance visibility for local eateries, enabling them to attract a broader customer base in the future.</p>

                    <p>Seamless Ordering and Delivery: In the future, users will be able to leverage Followone Foods to place orders for delivery from their favorite restaurants. The platform will collaborate with various delivery services to enable a seamless and convenient experience for users looking to enjoy restau-rant-quality meals in the comfort of their homes.</p>

                    <p>Restaurant Booking Tool: As part of our future roadmap, Followone Foods envisions the introduc-tion of a restaurant booking tool. This upcoming feature will allow users to reserve tables at their preferred restaurants, enhancing the dining experience by offering a convenient and efﬁcient reser-vation system.</p>

                    <p>Local Food and Grocery Listings: Followone Foods is extending its reach to local food stores and grocery outlets, allowing them to list their products online in the future. This upcoming feature will enable consumers to check product availability, facilitating a convenient and efﬁcient shopping experience for everyday essentials.</p>

                    <p>By combining the future offerings of restaurants, local food stores, and grocery outlets, Followone Foods aims to create a comprehensive platform that will serve the diverse culinary needs while enhancing the overall food exploration and ordering process for users.</p>

                    <p>We plan to roll out Followone Foods by the end of 2026.</p>

                </div>
            </div>
            
            <div className="followone-com-social">
                <div className="followone-com-logo">
                    <img src={LogoSocial} alt="Followone Social" />
                </div>
 
                <div className="social-video">
                    <video
                        src={socialVideo}
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{ width: '100%' }}
                    />
                </div>

                <div className="followone-com-social-profile-page">
                    <h3>Profile Page</h3>

                    <div className="followone-com-content">
                        <p>The Profile Page on Followone, is a modern digital business card that encapsulates the essence of users and businesses within a sleek and compre- hensive interface. Users and businesses can showcase their identity with a proï¬le photo and cover photo, aiming to facilitate instant recognition. The Follow button is intended to foster a dynamic connection between users, allowing them to stay updated on each other's activities.</p>

                        <p>The Business Information section serves as a quick snapshot, featuring a concise description, location details, and contact information. Users can effortlessly explore the network by viewing the list of followers and those they are following, enhancing connectivity.</p>

                        <p>However, the Proﬁle Page is more than just a static display – it's a vibrant hub for users and businesses to exhibit their offerings. From listings and posts to products, cars, real estate properties, jobs, services, food, travel, blogs, photo galleries, videos, and events – everything is seamlessly organized. It acts as a "small website" dedicated to showcasing the diverse inventory and content of each user and business, offering a rich and immersive experience for both businesses and their customers.</p>
                    </div>
                </div>

                <div>
                    <div className="benefit-box">
                        <div className="benefit-box-list benefit-box-list_right">
                            <h3>Profile Page</h3>

                            <table>
                                <tbody>
                                    <tr>
                                        <td><i className="fal fa-images"></i></td>
                                        <td>The user can add a cover image or an advertising banner.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-user-tie"></i></td>
                                        <td>The user can upload his logo or a profile photo.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-chart-network"></i></td>
                                        <td>Followone button allows users to send a follow request and build your community.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-cog"></i></td>
                                        <td>The profile navigation can be customized in the Listing Settings.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-money-bill-alt"></i></td>
                                        <td>Sell products, cars, real estate residential & commercial properties, business opportunities, foods and travel packages.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-users"></i></td>
                                        <td>Build your community, share events, photos, videos & blogs.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-briefcase"></i></td>
                                        <td>Grow your community by sharing job opportunities.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-wrench"></i></td>
                                        <td>Post your services and connect to our entire community.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-shield-check"></i></td>
                                        <td>Boost credibility by beeing a Followone Accredited Business.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-info-circle"></i></td>
                                        <td>The about section provides quick contact info and business description.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-star"></i></td>
                                        <td>Build credibility and popularity with Followone reviews.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-chart-line"></i></td>
                                        <td>Profile stats show’s the popularity of your profile: no. of followers, no. of listings and no. of prof ile views.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="benefit-box-img">
                            <img src={ImgBenProfilePage} alt="Profile Page" />
                        </div>
                    </div>


                    <div className="benefit-box">
                        <div className="benefit-box-list">
                            <h3>Profile Listing Page</h3>

                            <table>
                                <tbody>
                                    <tr>
                                        <td><i className="fal fa-search"></i></td>
                                        <td>The profile search allows the user to search inside your inventory.</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fal fa-plus-circle"></i></td>
                                        <td>Post your entire inventory*, up to 1M listings and build your online store or showroom. </td>
                                    </tr>
                                </tbody>
                            </table>

                            <p className="note">*For Auto & Real Estate we add, maintain and update your inventory.</p>
                        </div>

                        <div className="benefit-box-img">
                            <img src={ImgBenProfileListingPage} alt="Profile Listing Page" />
                        </div>
                    </div>
                </div>

                <div className="followone-com-social-profile-page">
                    <h3>Social Media Available Features</h3>

                    <div className="followone-com-content">

                        <h4>Chat Feature</h4>

                        <p>Chat is a tool designed for communication with businesses, and connections that users follow.</p>

                        <p>Anticipating the needs of our users, we plan a standalone Chat Application that will provide a dedicated space for better commu-nication. This upcoming feature will not only retain the core functionalities of one-on-one conversations but also introduce new capabilities to allow businesses to better communicate with consumers.</p>

                        <h4>Blogging</h4>

                        <p>Our user-friendly blog tool is designed to provide an easy-to-navigate interface for individuals and businesses alike. Users can create and schedule blogs, allowing them to share their thoughts, experiences, and expertise in a structured and engaging format.</p>

                        <p>For businesses, we believe that the Blog Feature becomes a powerful communication tool, leveraging this platform to share valu-able knowledge, insightful guides, advertise new products, offer industry insights, or connect with customers on a more personal level.</p>

                        <h4>Photo Galleries</h4>

                        <p>Photos allow users and businesses to show-case their visual narratives. Our platform allows users to create and curate photo galleries that reﬂect their unique experiences. From personal milestones to cherished moments, users can share their visual stories.</p>

                        <p>For businesses, the Photo Galleries feature is intended to serve as a powerful marketing tool. Sharing eye-catching product shots, sneak peeks of upcoming releases, event highlights, and more. It's a visual journey that connects businesses with their audience on a personal level, aiming to foster engagement and brand loyalty.</p>

                        <h4>Events</h4>

                        <p>The Events Hub offers a comprehensive platform to create, share, and manage events.</p>

                        <p>Businesses can leverage the Events Hub to announce product launches, store openings, promotional events, sales, and more.</p>

                    </div>

                    <h3>Social Media Features in Development</h3>

                    <div className="followone-com-content">

                        <h4>Personalized Wall</h4>

                        <p>A dynamic space where users can curate their digital experience, staying connected with the latest updates from businesses they follow.</p>

                        <p>On this interactive wall, users will have the freedom to post and share their own content, including listings, notes, photos, videos, and events. Whether it's a personal milestone, a business announcement, or an upcoming event, the Personalized Wall serves as a central hub for Businesses and users to share, engage, and stay informed within their social network.</p>

                        <p>With Followone, users can seamlessly blend personal and professional aspects of their lives, creating a rich and diverse social media experience.</p>

                        <p>We plan to roll out the personalized wall by the end of 2026.</p>

                        <h4>Videos</h4>

                        <p>Followone Videos is a platform where busi-nesses bring their stories to life through captivating videos. Users have the freedom to share vlogs, and creative expressions. For businesses, the Video Showcase is a powerful marketing tool to engage audiences. Showcase product demonstrations, behind-the-scenes footage, promotional content, and more. It allows businesses to connect with their customers on a deeper level.</p>

                        <p>We plan to roll out Followone Videos by the end of 2024.</p>

                        <h4>Groups</h4>

                        <p>Our upcoming Groups feature will empower users to join specialized interest groups, creating vibrant communities centered around shared passions, hobbies, and inter-ests.</p>

                        <p>Businesses can beneﬁt from this feature by creating industry-speciﬁc groups, enhancing their engagement with customers who are genuinely interested in their products or services.</p>

                        <p>We plan to roll out Followone Groups by the end of 2025.</p>

                    </div>
                </div>
            </div>

            <div className="followone-com-interface-design">
                <h2>Interface Design</h2>

                <img className="img-full" src={ImgBenInterfaceDesign} alt="Interface Design" />

                <div className="benefit-box">
                    <div className="benefit-box-list">
                        <h3>Home Page</h3>

                        <table>
                            <tbody>
                                <tr>
                                    <td><i className="fal fa-map-marker-alt"></i></td>
                                    <td>We detect user’s location to display listings from his city / region. Location data is not connected to his account so even if the user is not logged in he will see listings that are close to him.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-search"></i></td>
                                    <td>Dedicated category search allows users to find what they need straight from the home page of the website or home screen of the Followone App.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="benefit-box-img">
                        <img src={ImgBenHome} alt="Home Page" />
                    </div>
                </div>


                <div className="benefit-box">
                    <div className="benefit-box-list benefit-box-list_right">
                        <h3>Category Page</h3>

                        <table>
                            <tbody>
                                <tr>
                                    <td><i className="fal fa-search"></i></td>
                                    <td>Dedicated category search combined with most used filters allows the user to quickly find what he’s looking for.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-search-plus"></i></td>
                                    <td>Advanced search allows to user to narrow the search results by using keywords, dealer name, location, filters & more.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-search"></i></td>
                                    <td>Sidebar keywords search allows the user to search by keywords or dealership name and filters are another convenient way to narrow down search results.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-plus-circle"></i></td>
                                    <td>Post 1.5k to 1M Listings with our Premium or Enterprise subscription packages.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="benefit-box-img">
                        <img src={ImgBenCategory} alt="Category Page" />
                    </div>
                </div>


                <div className="benefit-box">
                    <div className="benefit-box-list">
                        <h3>Listing Preview</h3>

                        <table>
                            <tbody>
                                <tr>
                                    <td><i className="fal fa-money-bill-alt"></i></td>
                                    <td>FSP (Followone Suggested Price), available only on Followone Auto,  gives our users a starting point to negociate.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-heart"></i></td>
                                    <td>Users can like your listings boosting your credibility.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-eye"></i></td>
                                    <td>A view is added after a users is on the listing page for at least 10 seconds, boosting popularity.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-share-alt"></i></td>
                                    <td>Share the listing on Facebook, Twitter, Pinterest, WhatsApp, Viber, Telegram or copy the link.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-bookmark"></i></td>
                                    <td>Users can bookmark their favorites items and see them later in their favorites list available from the profile page.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="benefit-box-img">
                        <img src={ImgBenListingPreview} alt="Listing Preview" />
                    </div>
                </div>


                <div className="benefit-box">
                    <div className="benefit-box-list benefit-box-list_right">
                        <h3>Listing Detail Page</h3>

                        <table>
                            <tbody>
                                <tr>
                                    <td><i className="fal fa-search"></i></td>
                                    <td>Dealership location map allows the user to easily get directions.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-images"></i></td>
                                    <td>The image slider allows the user to scroll through all images available for the listing.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-money-bill-alt"></i></td>
                                    <td>FSP gives our users a starting point to negociate.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-user-chart"></i></td>
                                    <td>Boost your credibility & popularity with likes, views, shares and favorites.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-user-tie"></i></td>
                                    <td>One click/tap contact includes: Followone chat, phone, email, directions and link to author’s website. </td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-chart-line"></i></td>
                                    <td>Linking back to user’s websites boost its SEO and Google Rankings. </td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-browser"></i></td>
                                    <td>Modern, easy to use interface allows the user to quickly scan the listing details in order to make a buying decision.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-list-ul"></i></td>
                                    <td>Options and features lists, ordered alphabetically allow to user to easily find the ones he’s interested in. </td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-images"></i></td>
                                    <td>The footer page photo gallery allows the user to see all photos that pertain to current listing at once.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="benefit-box-img">
                        <img src={ImgBenListingDetailPage} alt="Listing Detail Page" />
                    </div>
                </div>


                <div className="benefit-box">
                    <div className="benefit-box-list">
                        <h3>Listing Detail Sidebar</h3>

                        <table>
                            <tbody>
                                <tr>
                                    <td><i className="fal fa-user-tie"></i></td>
                                    <td>Listing owner’s branding, logo, name and link to prof ile page.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-phone-office"></i></td>
                                    <td>Listing owner’s contact information, category, and other details.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-plus-circle"></i></td>
                                    <td>Auto listings for dealers are managed by Followone, but users can add new listings and manage them manually.</td>
                                </tr>
                                <tr>
                                    <td><i className="fal fa-barcode-alt"></i></td>
                                    <td>Similar items section contains two more listings by the same business, displayed for free. </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="benefit-box-img">
                        <img src={ImgBenListingDetailSidebar} alt="Listing Detail Sidebar" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FollowoneCom;