import React, { useState } from "react";

const TeamMember = ({ image, name, role, location, points, learnMore }) => {

  const [showMore, setShowMore] = useState(false);

  return (
    <div className="team-member">
      <img src={image} alt={name} />
      <div className="team-member-details">
        <h4>{name}</h4>
        <p className="title">{role}</p>
        <p className="history">{location}</p>
        <ul>
          {points?.map((point) => {
            return <li>{point}</li>;
          })}
        </ul>
        
        <div className="learn-more">
          <button onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show Less" : "Learn More"}
          </button>

          {showMore && <p className="learn-more-text">{learnMore}</p>}
        </div>

      </div>
    </div>
  );
};

export default TeamMember;
