const SideBar = () => {
    return (
        <div className="sidebar">
            <h4>CONTENT</h4>
            <ul>
                <li><a href="#valuation">Valuation</a></li>
                <li><a href="#who-we-are">Who We Are</a></li>
                <li><a href="#core-pillars">Core Pillars</a></li>
                <li><a href="#business-verticals">Business Verticals</a></li>
                <li><a href="#company-structure">Company Structure</a></li>
                <li><a href="#management">Management</a></li>
                <li><a href="#culture-employees">Culture & Employees</a></li>
                <li><a href="#market-analysis">Market Analysis</a></li>
                <li><a href="#business-model">Business Model</a></li>
                <li><a href="#technology">Technology</a></li>
                <li><a href="#followone-ai">Followone AI</a></li>
                <li><a href="#followone-com">Followone.com</a></li>
            </ul>
        </div>
    );
};

export default SideBar;