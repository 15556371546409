import techVideo from '../../../../assets/about/tech-video.mov';
import followoneLogo from '../../../../assets/about/brand-followone-com.svg';
import adsB2BLogo from '../../../../assets/about/brand-adsb2b.svg';
import healthcareProGroupLogo from '../../../../assets/about/brand-healthcare-pro-group.svg';
import zakListLogo from '../../../../assets/about/brand-zaklist.svg';
import carVerveLogo from '../../../../assets/about/brand-carverve.svg';
import carzLookLogo from '../../../../assets/about/brand-carzlook.svg';

const Technology = () => {
    return (
        <div id="technology" className="section technology">
            <h2>Technology & Design</h2>

            <div className="technology-video">
                <video
                    src={techVideo}
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{ width: '100%' }}
                />
            </div>

            <div className="technology-icons">
                <i className="fa-brands fa-app-store" />
                <i class="fa-brands fa-google-play"></i>
                <i class="fa-brands fa-js"></i>
                <i className="fa-brands fa-node-js" />
                <i className="fa-brands fa-react" />
                <i class="fa-brands fa-npm"></i>
                <i class="fa-brands fa-sass"></i>
                <i class="fa-brands fa-css3"></i>
                <i class="fa-light fa-server"></i>
                <i class="fa-light fa-database"></i>
            </div>

            <div className="technology-content">

                <p>Followone, is a Software as a Service (SaaS) platform, is dedica ted to delivering exceptional value to businesses and users alike by integrating modern technologies across all v erticals. Leveraging a powerful tech stack that includes React.JS, React Native, Node.JS, and artificial intelligence (AI), Followone ensures optimal performance, scalability, and security across both desktop and mobile platforms. This technical foundation supports seamless business growth and expansion while maintaining a user-friendly experience.</p>

                <p>At the core of our business model is a user-ﬁrst approach, where the needs and expectations of users guide the development of our platform. Key drivers of our success—usability, ﬁndability, consistency, and simplicity—are built into every aspect of our design. AI plays an essential role in enhancing these principles, driving more personalized and efﬁcient user experiences, and shap-ing the future of our platform.</p>

                <p>Usability is a primary focus, making sure that users can effortlessly interact with Followone. AI enhances this experience by offering personalized recommendations, predictive insights, and streamlined interactions across all verticals, from real estate and auto to healthcare and services. Findability is also strengthened by AI, making it easier for users to quickly locate relevant content, listings, or services through advanced search and discovery features.</p>

                <p>Consistency is maintained throughout the platform, creating a familiar and reliable environment for users, no matter how the y engage with Followone. AI ensures that this experience remains cohesive, providing users with a seamless journey across different devices and touchpoints. Simplicity guides our design choices, ensuring the interface re mains intuitive and accessible, while AI works behind the scenes to deliv er smarter, more efficient tools that simplify complex tasks for both businesses and users.</p>

                <p>AI integration across all verticals—whether it's predictive pricing in real estate, personalized recommendations in auto, dynamic content gener ation for social media, or AI-driv en lead generation in services—will be crucial to the future of our platform. As we continue to de velop and scale Followone, AI will enable us to sta y ahead of industry trends, offer inno vative solutions, and create a more personalized experience for every user.</p>

                <p>By focusing on these core design principles and embr acing AI as a vital part of our str ategy, Followone continues to evolve and grow. We are committed to providing a robust and adaptable platform that meets the demands of today’s businesses while ensuring a seamless user experience across all verticals and devices. AI is not just a feature; it is a cornerstone of our fut ure development, driving innovation and ensuring that Followone remains at the forefront of the SaaS industry.</p>

            </div>

            <h3>Intelectual Property</h3>

            <div className="technology-ip">

                <p>Important to our business operations are the trademarks, service marks, and copyrights that our Company owns or has the rights to.</p>

                <p>In general, we use internal and external controls, such as contractual protections with employees, contractors, customers, and partners, to restrict access to and use of our proprietary technology and other confidential information.</p>

                <p>We currently hold or have filed for the following trademarks in the U.S.:</p>

                <table>
                    <tbody>
                        <tr>
                            <td>
                                <span>Followone™</span>
                                <span>REG. #: 7293027</span>
                                <span>REG. DATE: January 30, 2024</span>
                            </td>
                            <td></td>
                        </tr>

                        <tr>
                            <td>
                                <span>Followone.com™</span>
                                <span>REG. #: 7199805</span>
                                <span>REG. DATE: October 24, 2023</span>
                            </td>
                            <td><img src={followoneLogo} alt="Followone.com" /></td>
                        </tr>

                        <tr>
                            <td>
                                <span>adsB2B™</span>
                                <span>REG. #: 7192852</span>
                                <span>REG. DATE: October 17, 2023</span>
                            </td>
                            <td><img src={adsB2BLogo} alt="adsB2B" /></td>
                        </tr>

                        <tr>
                            <td>
                                <span>CarzLook™</span>
                                <span>REG. #: 7293034</span>
                                <span>REG. DATE: January 30, 2024</span>
                            </td>
                            <td><img src={carzLookLogo} alt="CarzLook" /></td>
                        </tr>

                        <tr>
                            <td>
                                <span>ZakList™</span>
                                <span>REG. #: 7199808</span>
                                <span>REG. DATE: October 24, 2023</span>
                            </td>
                            <td><img src={zakListLogo} alt="ZakList" /></td>
                        </tr>

                        <tr>
                            <td>
                                <span>Healthcare Pro Group</span>
                                <span>Status: PENDING</span>
                                <span>SERIAL #: 97545263</span>
                                <span>FILED DATE: August 11, 2022</span>
                            </td>
                            <td><img src={healthcareProGroupLogo} alt="Healthcare Pro Group" /></td>
                        </tr>

                        <tr>
                            <td>
                                <span>CarVerve</span>
                                <span>Status: PENDING</span>
                                <span>SERIAL #: 97541643</span>
                                <span>FILED DATE: August 9, 2022</span>
                            </td>
                            <td><img src={carVerveLogo} alt="CarVerve" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Technology;