import WhoWeAre1 from '../../../../assets/about/who-we-are-1.jpeg';
import WhoWeAre2 from '../../../../assets/about/who-we-are-2.jpeg';

const WhoWeAre = () => {
    return (
        <div id="who-we-are" className="section whoweare">
            <h2>Who We Are</h2>

            <div className="who-we-are-content">
                <div className="who-we-are-content-item">
                    <div className="who-we-are-bkg" style={{ backgroundImage: `url(${WhoWeAre1})` }}></div>
                    <p>Followone is a social media business platform that uniquely integrates multiple sectors—Auto, Real Estate, Healthcare, and E-commerce—into one unified space. Future expansions will include Food, Jobs, Services, and Travel, offering an all-in-one platform where users can seamlessly buy, sell, post, and explore products, services, jobs, and vacation packages. This comprehensive approach provides a streamlined, versatile experience for businesses and individuals, making Followone a powerful solution for engaging across diverse industries.</p>
                </div>  

                <div className="who-we-are-content-item">
                    <div className="who-we-are-bkg" style={{ backgroundImage: `url(${WhoWeAre2})` }}></div>
                    <p>Followone has strategized a multifaceted approach to revenue generation, anchored by a blend of advertising, software licensing, and strategic mergers and acquisitions. Advertising will play a significant role, leveraging our platform to offer targeted opportunities for businesses. Additionally, we believe that software licensing will enable us to monetize our proprietary technologies, offering other businesses the chance to leverage our cutting-edge solutions. Lastly, we intend to engage in strategic mergers and acquisitions, allowing us to expand our service offerings, enter new markets, and acquire innovation.</p>
                </div>
            </div>  
        </div>  
    );
};

export default WhoWeAre;