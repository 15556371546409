import aboutImage from '../../../../assets/about/culture-employee.jpeg';
import { Link } from 'react-router-dom';

const CultureEmployees = () => {
    return (
        <div id="culture-employees" className="section culture-employees">

            <h2>Culture & Employees</h2>

            <img src={aboutImage} alt="Culture & Employees" />

            <div className="culture-employees-content">

                {/* <p>As of July 20, 2024, the Company currently has 12 full-time employees and no part-time employees.</p> */}

                <p>At Followone, our approach to hiring and nurturing talent is deeply rooted in fostering loyalty and encouraging self-education. We believe in investing in individuals who show not just skill but also a passion for continuous learning and growth. Our hiring process is designed to identify and attract those who are not only talented but also curious and self-driven. Once part of the Followone family, employees are encouraged and supported in their pursuit of self-education. We provide access to resources, training programs, and a work environment that champions personal development and professional growth. We believe that this approach not only helps in cultivating a team of deeply committed and skilled professionals but also ensures that our workforce remains at the forefront of industry developments. By investing in our people, we are building a culture of loyalty and innovation, where each member is empowered to contribute their best and grow alongside the Company.</p>

                <p>Our Company culture at Followone Inc. is built on creativity, teamwork, and a strong dedication to quality. Our team is a diverse group of accomplished individuals who each bring their special talents and viewpoints to our fast-paced work environment.</p>

                <p>Our success is largely dependent on our culture and workforce. We take pride in our "hacker culture," which we describe as an office setting that encourages quick thinking and innovative problem-solving. We make an effort to produce new items quickly, then we iterate and tweak them continuously to make them even better. We seek team members who possess a strong desire for continuous learning and professional development, and who are genuinely passionate about their work. Our commitment to the ongoing professional growth of our team is a key focus of our investment in our people.</p>

                <p>We just took on a large project and hired over 300 independent contractors for a limited time, demonstrating our flexibility. This calculated action was taken with the intention of thoroughly evaluating our apps and website to make sure they live up to our high expectations for functionality and user experience.</p>

                <p>This program demonstrates Followone Inc.'s dedication to user satisfaction and ongoing improvement, two pillars of the company culture. Our passion for creating outstanding digital experiences unites both our contractors and employees, reflecting our core values of innovation, excellence, and customer-centricity.</p>

                {/* <p>We have never had a work stoppage, and none of our employees are represented by a labor union. Management believes the Company’s relationship with our employees is good.</p> */}

                <h3>Code of Business Conduct and Ethics</h3>

                <p>Prior to listing on the Nasdaq Capital Market, we will adopt a code of business conduct and ethics that applies to all of our employees, officers and directors, including those officers responsible for financial reporting. The code of business conduct and ethics is available here: <Link to="/code-of-conduct">Code of Conduct</Link></p>
            </div>
        </div>
    );
};

export default CultureEmployees;